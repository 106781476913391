import React, {Component} from "react";
import "./AddOverviewGroupModal.scss";
import '../../styles/_layout.scss';
import {Button, Card, CardBody, CardHeader, Dropdown, GdAutocomplete, Icon, Input, Loader} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import {Grid} from "@material-ui/core";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";


class AddOverviewGroupModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.sitesInGroupChanged = this.sitesInGroupChanged.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSiteChange = this.onSiteChange.bind(this);
        this.getSites = this.getSites.bind(this);
        this.errors = {};
        this.changed = false;

        this.state = {
            hubs: [],
            sitesInGroup: [],
            sites: [],
            siteGroup: {
                name: ''
            },
            loaded: false
        }
        Promise.all([
            GridDuck.getSites({getAll: true})
        ]).then(function (results) {
            if (!self.modalClosed) self.setState({
                sites: results[0].list.map(function (sg) {
                    if (sg.id !== 'all') return {title: sg.name, value: sg.id, id: sg.id}
                    else return {title: null, value: null, id: null}
                }),
                loaded: true
            })
        });
        this.modalClosed = false;
    }

    getSites(searchTerm) {
        return GridDuck.getSites({
            filters: [{
                field: 'search',
                value: searchTerm
            }]
        }).then(function (siteList) {
            return Promise.resolve(
                siteList.list.map(function (sg) {
                    if (sg.id !== 'all') return {title: sg.name, value: sg.id, id: sg.id}
                    else return {title: null, value: null, id: null}
                })
            )
        })
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        this.state.siteGroup[val.target.name] = val.target.value;
        this.setState(this.state);
    }

    onSiteChange(val, newValue) {
        this.state.site = newValue;
        this.setState(this.state);
    }

    saveChanges() {
        let self = this;

        this.setState({triedToSave: true});
        console.log(this.errors, ' : errors');
        console.log(this.state.siteGroup, ' : site group');
        if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) { return reject();}).catch(function () {
            return Promise.reject();
        });

        return GridDuck.createSiteGroup(this.state.siteGroup).then(function (res) {
            history.push('/siteGroup/' + res.id);
            self.closeModal();
        })
    }

    checkErrors() {
        let errors = {};
        if (!this.state.siteGroup.name || !this.state.siteGroup.name.length) errors['name'] = true;
        // if (!this.state.siteGroup.siteIds || !this.state.siteGroup.siteIds.length) errors['sitesInGroup'] = true;
        this.errors = errors;
        return errors;
    }

    sitesInGroupChanged(fakeEv, newValue, ev) {
        this.state.sitesInGroup = newValue;
        console.log(this.state.sitesInGroup, ' : sitesInGroup');
        this.state.siteGroup.siteIds = newValue.map(function (s) {
            return s.id;
        });
        this.setState(this.state)
    }


    render() {
        this.checkErrors();
        let footer;
        if (this.state.loaded) footer = <Button label={'Add Site Group'} progressRes additionalclasses={'md'}
                                                color={'gd-green'}
                                                onClick={this.saveChanges}/>;
        return (
            <GdModal
                open={this.props.open}
                title={'Create Site Group'}
                footer={footer}
                contentLabel={'Add Site'}
                onClose={this.closeModal}>
                {this.state.loaded ? <CardBody>
                    <div style={{marginBottom: '15px'}}>
                        <Input required error={this.errors['name'] && this.state.triedToSave} top='true'
                               onChange={this.onFormChange}
                               name={'name'} value={this.state.siteGroup.name} label={'Name'}/>
                    </div>
                    <div style={{marginBottom: '30px'}}>
                        <GdAutocomplete filterSelectedOptions
                                        multiple
                                        value={this.state.sitesInGroup}
                                        name={'siteGroups'}
                                        placeholder={'Select sites'}
                                        options={this.state.sites}
                                        getOptionSelected={function (option, value) {
                                            if (option && value) return option.id === value.id;
                                        }}
                                        onChange={this.sitesInGroupChanged}
                                        label="Sites"/>
                    </div>

                </CardBody> : ''}
                {/*{this.state.loaded ? <CardBody>*/}
                {/*    <p style={{*/}
                {/*        textAlign: 'center',*/}
                {/*        fontSize: '19px',*/}
                {/*        fontWeight: '700',*/}
                {/*        marginBottom: '30px'*/}
                {/*    }}>No available hubs</p>*/}
                {/*    <p style={{*/}
                {/*        color: '#424242',*/}
                {/*        fontSize: '14px'*/}
                {/*    }}>You do not currently have any sites, you can create one with clicking the +New button at*/}
                {/*        the*/}
                {/*        top right of the dashboard and choosing site.</p>*/}
                {/*</CardBody> : ''}*/}
                {!this.state.loaded ? <CardBody>
                    <GenericLoader/>
                </CardBody> : ''}
            </GdModal>
        )
    }
}

export default AddOverviewGroupModal;