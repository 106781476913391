import React from 'react';
import GridDuck from 'gridduck';
import 'css-doodle'
import cookie from 'react-cookies'
import history from "../meta/history";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginError: '',
            submitting: false,
            progressState: '',
            showToast: false,
            mfaToken: '',
            verificationToken: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loggedIn = this.loggedIn.bind(this);

        this.handleMFASubmit = this.handleMFASubmit.bind(this);
        this.handlMFAChange = this.handlMFAChange.bind(this);

        this.onVerificationTokenChange = this.onVerificationTokenChange.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    componentDidMount() {
        console.log(this.props.location, ' : this.props.location')
        if (this.props.location.state) {
            console.log(this.props.location.state, ' : state');
            this.setState({showToast: this.props.location.state.showToast})
        }

        //OVERWRITE TO SAVE FROM COOKIE DELETION
        // GridDuck.config.baseUrl = 'http://localhost:3000/';
        // cookie.save('baseUrl', 'http://localhost:3000/', {path: '/'})
    }

    handlMFAChange(e) {
        this.setState({mfaError: null, mfaToken: e.target.value});
    }

    handleMFASubmit() {
        let self = this;
        self.setState({submitting: true, progressState: 'loading'});
        return GridDuck.createMfaAuthentication({
            token: this.state.mfaToken
        })
            .then(function (res) {
                console.log("MFAd");
                let accessToken = GridDuck.config.accessToken;
                delete accessToken.scope;
                cookie.save('accessToken', accessToken, {path: '/'})
                self.setState({loggedIn: true, submitting: false})
                return Promise.resolve();
            })
            .catch(function (err) {
                console.log("Error", err);
                setTimeout(function () {
                    self.setState({mfaError: '', progressState: ''});
                }, 2500)
                self.setState({submitting: false, progressState: 'failed', mfaError: 'Incorrect MFA Token'});
                return Promise.reject();
            });
    }


    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }
        let self = this;
        self.setState({triedToSubmit: true});
        if (!this.state.username.length || !this.state.password.length) {
            return Promise.reject()
        }

        self.setState({submitting: true, progressState: 'loading'});
        return GridDuck.login(this.state.username, this.state.password).then(function (res) {
            self.setState({submitting: false});
            let accessToken = GridDuck.config.accessToken;
            delete accessToken.scope;
            cookie.save('accessToken', accessToken, {path: '/'});
            if (history.completeUrl && history.completeUrl !== '/') {
                history.push(history.completeUrl);
            } else {
                history.push('/siteGroup/all');
            }
            return Promise.resolve();
        }).catch(function (err) {
            console.log("Login failure", err);
            //TODO, different errors
            if (err === 'Incorrect Credentials') {
                err = 'Wrong username or password'
            } else if (err === 'MFA Required') {
                self.setState({
                    mfaRequired: true,
                    submitting: false,
                    progressState: ''
                });
                return Promise.resolve();
            } else if (err === 'MFA Setup Required') {
				console.log("Setup MFA");
                return GridDuck.createMfaSecret({}).then(function (mfaSecret) {
                    self.setState({
                        mfaSetupRequired: true,
                        mfaSetupImage: mfaSecret.secretUrl,
                        submitting: false,
                        progressState: ''
                    });
                    return Promise.resolve();
                })
            } else {
                err = 'Unknown error';
            }
            self.setState({loginError: err, submitting: false, progressState: 'failed'});
            setTimeout(function () {
                self.setState({loginError: '', progressState: ''});
            }, 2500)
            return Promise.reject();
        });
    }

    onVerificationTokenChange(e) {
        this.setState({verificationToken: e.target.value});
    }

    verifyToken() {
        let self = this;
        self.setState({submitting: true, progressState: 'loading', mfaError: null});
        return GridDuck.createMfaVerification({token: this.state.verificationToken})
            .then(function () {
                let accessToken = GridDuck.config.accessToken;
                delete accessToken.scope;
                cookie.save('accessToken', accessToken, {path: '/'});
                self.setState({loggedIn: true, submitting: false})
                return Promise.resolve();
            })
            .catch(function (err) {
                console.log("Error", err);
                setTimeout(function () {
                    self.setState({mfaError: '', progressState: ''});
                }, 2500)
                self.setState({submitting: false, progressState: 'failed', mfaError: 'Incorrect verification token'});
                return Promise.reject();
            })
    }

    loggedIn() {
        if (!this.state.mfaRequired) {
            this.setState({loggedIn: true});
        }
    }

}

export default Login;
