import "./Legend.scss";
import React from "react";
import {Button, DateTimeRangePicker, Dropdown, Icon, Tooltip, GdCheckbox} from "gd-react";
import moment from "moment-timezone";
import LegendRange from "./LegendRange";
import {floorFiveMinutes} from "../services/dateManipulation";
import TariffBreakdown from "./TariffBreakdown";
import InteractiveTooltip from "./InteractiveTooltip";
import LegendMenu from "./LegendMenu";
import OverlayMenu from "./OverlayMenu";
import OccupancyScheduleSetterMenu from "./OccupancyScheduleSetterMenu";

class Legend extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onClick = this.onClick.bind(this);
        // this.reset = this.reset.bind(this);
        this.toggleShowAll = this.toggleShowAll.bind(this);
        this.toggleLegend = this.toggleLegend.bind(this);
        this.rangeUpdated = this.rangeUpdated.bind(this);
        this.compareRangeUpdated = this.compareRangeUpdated.bind(this);
        this.dateSelection = this.dateSelection.bind(this);
        this.granularitySelection = this.granularitySelection.bind(this);
        this.cancelComparing = this.cancelComparing.bind(this);
        this.setInitialComparison = this.setInitialComparison.bind(this);
        this.onIsComparingChange = this.onIsComparingChange.bind(this);
        this.onSelectionTypeChange = this.onSelectionTypeChange.bind(this);
        this.state = {
            start: this.props.start,
            end: this.props.end,
            dateSelection: this.props.dRString ? this.props.dRString : this.props.isAll ? 'yesterday' : 'today_so_far',
            granularity: this.props.graphType === 'hour_of_day' ? 60 * 60 : this.props.granularity ? this.props.granularity : 60 * 30,
            hidden: [],
            isComparing: !!this.props.cRString,
            open: true,
            compareSelectValue: []
        }
    }

    onIsComparingChange(isComparing) {
        this.setState({isComparing: (isComparing === 'custom')});
    }

    onSelectionTypeChange(type) {
        this.setState({dateSelection: type});
    }

    toggleShowAll(showAll) {
        if (showAll) {
            this.props.onHide([]);
            this.setState({
                highlighted: [],
                modified: true,
                hidden: []
            });
        } else {
            let ids = this.props.items.map((i) => i.id)
            this.props.onHide(ids);
            this.setState({
                highlighted: [],
                modified: true,
                hidden: ids
            });
        }
        this.props.onHover([]);
        this.props.resetZoom();
    }

    granularitySelection(e) {
        let self = this;
        let granularity = e.target.value;
        self.setState({
            granularity: granularity,
            highlighted: [],
            hidden: []
        }, function () {
            if (self.state.compareStart) {
                self.props.set(self.state.start, self.state.end, {
                    start: self.state.compareStart,
                    end: self.state.compareEnd,
                    show: true
                }, granularity);
            } else {
                self.props.set(self.state.start, self.state.end, null, granularity);
            }
        });
    }

    dateSelection(e) {
        let self = this;
        let start;
        let end;
        let granularity;
        switch (e.target.value) {
            case 'last_15_mins':
                start = moment().tz(self.props.timezone).subtract(15, 'minutes').unix();
                end = moment().tz(self.props.timezone).unix();
                granularity = 30;
                break;
            case 'today_so_far':
                start = moment().tz(self.props.timezone).startOf('day').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = 60 * 30;
                break;
            case 'yesterday':
                start = moment().tz(self.props.timezone).subtract(1, 'day').startOf('day').unix()
                end = moment().tz(self.props.timezone).startOf('day').unix();
                granularity = 60 * 30;
                break;
            case 'week_so_far':
                start = moment().tz(self.props.timezone).startOf('isoWeek').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 60 * 24;
                break;
            case 'last_week':
                start = moment().tz(self.props.timezone).subtract(7, 'day').startOf('isoWeek').unix();
                end = moment().tz(self.props.timezone).startOf('isoWeek').unix();
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 60 * 24;
                break;
            case 'month_so_far':
                start = moment().tz(self.props.timezone).startOf('month').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 60 * 24;
                break;
            case 'last_month':
                start = moment().tz(self.props.timezone).subtract(1, 'month').startOf('month').unix();
                end = moment().tz(self.props.timezone).startOf('month').unix();
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 60 * 24;
                break;
            case 'year_so_far':
                start = moment().tz(self.props.timezone).startOf('year').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 'monthly';
                break;
            case 'last_year':
                start = moment().tz(self.props.timezone).subtract(1, 'year').startOf('year').unix();
                end = moment().tz(self.props.timezone).startOf('year').unix();
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 'monthly';
                break;
            case 'custom':
                this.setState({
                    dateSelection: e.target.value,
                    highlighted: [],
                    hidden: []
                });
                return;
            default:
                start = moment().tz(self.props.timezone).startOf('day').unix();
                end = moment().tz(self.props.timezone).unix();
                break;
        }
        this.setState({
            dateSelection: e.target.value,
            start: start,
            end: end,
            highlighted: [],
            hidden: [],
            isComparing: false,
            compareStart: null,
            compareEnd: null,
            granularity: granularity,
        }, function () {
            self.props.set(self.state.start, self.state.end, null, granularity);
        })
    }

    cancelComparing() {
        let self = this;
        this.setState({
            isComparing: false,
            compareStart: null,
            compareEnd: null,
            highlighted: [],
            hidden: []
        }, function () {
            self.props.set(self.state.start, self.state.end, null, self.state.granularity);
        });
    }

    rangeUpdated(e) {
        //TODO: work out granularity
        let self = this;
        this.setState({
            start: e.start.getTime() / 1000,
            end: e.end.getTime() / 1000,
            isComparing: false,
            compareStart: null,
            compareEnd: null,
            highlighted: [],
            hidden: []
        }, function () {
            self.props.set(self.state.start, self.state.end, null, self.state.granularity);
        })

    }

    setInitialComparison() {
        let self = this;
        let curStartUnix = self.state.start;
        let curEndUnix = self.state.end;
        let start = curStartUnix - (curEndUnix - curStartUnix);
        let compareSelectValue = [new Date(start * 1000), new Date(curStartUnix * 1000)];
        this.setState({isComparing: true, compareSelectValue: compareSelectValue}, () => {
            this.compareRangeUpdated({start: start, end: curStartUnix, manually: true})
        });
    }

    compareRangeUpdated(e) {
        let self = this;
        this.setState({
            compareStart: e.manually ? e.start : e.start.getTime() / 1000,
            compareEnd: e.manually ? e.end : e.end.getTime() / 1000,
            highlighted: [],
            hidden: []
        }, function () {
            self.props.set(self.state.start, self.state.end, {
                start: self.state.compareStart,
                end: self.state.compareEnd,
                show: true
            }, self.state.granularity);
        })
    }

    toggleLegend() {
        this.setState({open: !this.state.open});
    }

    onMouseEnter(item) {
        if (this.props.onHover) {
            if (!~this.props.highlighted.indexOf(item.id)) {
                let list = [item.id];
                this.props.onHover(list);
            }
        }
    }

    onMouseLeave(item) {
        if (this.props.onHover) {
            if (~this.props.highlighted.indexOf(item.id)) {
                let list = [];
                this.props.onHover(list);
            }
        }
    }

    onClick(item) {
        if (this.props.onHide) {
            //if DOES NOT exist in the hidden list (not hidden)
            if (!~this.state.hidden.indexOf(item.id)) {
                this.setState(state => {
                    let list = [...state.hidden, item.id];
                    this.props.onHide(list);
                    return {
                        modified: true,
                        hidden: list,
                    };
                });
            } else {
                //if exists already (already hidden)
                this.setState(state => {
                    const list = state.hidden.filter(hitem => hitem !== item.id);
                    this.props.onHide(list);
                    return {
                        modified: true,
                        hidden: list,
                    };
                });
            }
        }
    }

    createLegendItem(item) {
        let className = "legend-item";

        if (~this.props.highlighted.indexOf(item.id)) {
            className += " highlighted";
        }
        if (~this.state.hidden.indexOf(item.id)) {
            className += " hidden";
        }
        let data;
        if (this.props.data) {
            data = this.props.data.data.find(d => d.name === item.name);
        }
        let heatmapElement;
        if (this.props.graphType === 'heatmap') {
            let heatmapIndicator;
            if (data) {
                heatmapIndicator = (<div className={'heatmap-indicator'} style={{"left": data.percent + "%"}}>
                    <Icon size={'14'} icon={"FaAngleUp"} color={'gd-grey'}/>
                </div>)
            }
            heatmapElement = (
                <div className={'heatmap-legend'}
                     style={{"background": "linear-gradient(to right, whitesmoke 0%, lightgreen 25%, green 75%, yellow 87.5%, red 100%)"}}>
                    {heatmapIndicator}
                </div>
            )
        }
        let tooltipOnClosed;
        if (!this.state.open) {
            tooltipOnClosed = <Tooltip label={item.name} position={'left'}>
                <div key={item.id} onMouseEnter={() => this.onMouseEnter(item)}
                     onMouseLeave={() => this.onMouseLeave(item)}
                     className={className}>
                    <div className={"legend-column"}>
                        <div className={"legend-row"}>
                            <div className={"legend-indicator"}
                                 style={{backgroundColor: data && data.color ? data.color : item.color}}/>
                            <div className={"legend-label"}>{item.name}</div>
                        </div>
                    </div>
                </div>
            </Tooltip>
        } else {
            tooltipOnClosed = <div key={item.id} onMouseEnter={() => this.onMouseEnter(item)}
                                   onMouseLeave={() => this.onMouseLeave(item)}
                                   className={className}
            >
                {heatmapElement}
                <div className={"legend-column"}>
                    <div className={"legend-row"}>
                        <div className={"legend-indicator"}
                             style={{backgroundColor: data && data.color ? data.color : item.color}}/>
                        {this.showSubMenu() ? <LegendMenu
                            graphType={this.props.graphType}
                            itemType={this.findItemType()}
                            ref={this.customKpiMenuRef}
                            key={item.id + 'm'}
                            item={item}
                        >
                            <div className={"legend-label"} style={{cursor: "pointer"}}>{item.name}</div>
                        </LegendMenu> : <div className={"legend-label"}>{item.name}</div>}


                        <div className={"rear-container"}>
                            {this.showSubMenu(item) ? <LegendMenu
                                graphType={this.props.graphType}
                                itemType={this.findItemType()}
                                ref={this.customKpiMenuRef}
                                key={item.id + 'm'}
                                item={item}
                            >
                                <div className={'legend-icon'}>
                                    <Icon icon={"FaEllipsisH"} color={'gd-grey'}/>
                                </div>
                            </LegendMenu> : null}
                            {this.showSubMenu(item) ? <div
                                className={'legend-icon'}
                                onClick={() => this.onClick(item)}
                            >
                                <Icon icon={~this.state.hidden.indexOf(item.id) ? "FaEyeSlash" : "FaEye"}
                                      color={'gd-grey'}/>
                            </div> : null}
                        </div>
                    </div>
                    <div className={"legend-value"}>{data ? data.compareValue ? (<span>{data.value} <span
                        className={"comparison"}>({data.compareValue})</span></span>) : data.value : '-'}
                        {data && data.disconnected ? (<span><br/>[Device disconnected]</span>) : ''}
                    </div>

                </div>
            </div>;
        }

        return (
            tooltipOnClosed
        )
    }

    findItemType() {
        let options = [
            'site', 'siteGroup', 'device', 'hub', 'deviceGroup'
        ]
        let types = options.filter((o) => window.location.pathname.indexOf(o) > -1)
        let type;
        if (types.length > 1) {
            type = types[types.length - 1];
        } else {
            type = types[0];
        }
        return type
    }

    showSubMenu(item) {
        if (item && item.id === 'EXTERNAL_TEMP') {
            return false;
        }
        let itemType = this.findItemType()
        let combined = ~window.location.search.indexOf('combined')
        if (
            //historic graph type, line graphs only of multiple devices in a site, or multiple sites or devices within a site group
            (this.props.graph === 'line' && (itemType === 'site' || itemType === 'siteGroup') && !combined ||
                //device breakdown graph type
                (this.props.graphType === 'breakdown') ||
                //site breakdown graph type
                (this.props.graphType === 'site_breakdown'))) {
            return true
        } else {
            return false
        }
    }

    renderHeatmapLegend(item) {

    }

    render() {
        let legendItems = [];
        let self = this;
        if (this.props.items) {
            let items = this.props.items;
            console.log(items, ' : items');
            //deleted this sort, if we need it put it in the graph library
            // if (this.props.data) {
            //     items.sort(function (itemA, itemB) {
            //         let itemDataA = self.props.data.data.find(d => d.name === itemA.name);
            //         let itemDataB = self.props.data.data.find(d => d.name === itemB.name);
            //         if (itemDataA && itemDataB) {
            //             return itemDataB.rawVal - itemDataA.rawVal
            //         } else {
            //             return 0;
            //         }
            //     })
            // }
            items.forEach(function (item) {
                legendItems.push(
                    self.createLegendItem(item)
                );
            })
        }
        let className = "graph-legend";
        if (this.props.highlighted.length > 0) {
            className += " highlighted";
        }
        let tooltipDate;
        if (this.props.data && this.props.data.date) {
            tooltipDate = (<div className={"timestamp"}>
                {/*<span>{this.props.data.locked ? 'Locked to' : 'Hovering over'}</span>*/}
                <Icon size={11} color={'gd-grey'} icon={'FaCalendar'}/>
                <span className={"bold"}>{this.props.data.date}</span>
            </div>)
        }
        let tooltipDateCompare;
        if (this.props.data && this.props.data.compareDate) {
            tooltipDateCompare = (<div className={"timestamp"} style={{marginTop: '6px'}}>
                {/*<span>Comparing to</span>*/}
                <Icon size={11} color={'gd-grey'} icon={'FaArrowsAltH'}/>
                <span className={"bold"} style={{color: 'grey'}}>{this.props.data.compareDate}</span>
            </div>)
        }

        let allHidden = this.props.items?.length === this.state.hidden?.length;
        let showTemperature = this.props.overlays.indexOf('TEMPERATURE') !== -1;
        let _site = this.props.site || this.props.item;
        let overlayRow;
        let itemType = this.findItemType();
        if ((itemType === 'site' || itemType === 'device') && this.props.graph === 'line') {
            overlayRow = (
                <div className={'overlays row'} style={{"display": this.props.graph === 'line' ? "flex" : "none"}}>
                    <div className={'column'}>
                        <span className={'label'}>Overlay</span>
                        <div className={'checkbox-wrapper-wrapper'}>
                            <div className={'checkbox-wrapper'}>
                                <GdCheckbox checked={this.props.overlays.indexOf('TEMPERATURE') !== -1}
                                            name={'overlayTemperature'}
                                            onChange={function () {
                                                if (self.props.overlays.indexOf('TEMPERATURE') !== -1) {
                                                    self.props.overlays.splice(self.props.overlays.indexOf('TEMPERATURE'), 1);
                                                } else {
                                                    self.props.overlays.push('TEMPERATURE');
                                                }
                                                self.props.setOverlays(self.props.overlays)
                                            }}
                                            label={'External Temperature'}/>
                            </div>
                            <div className={'checkbox-wrapper'}>
                                {(_site.occupancyScheduleId || _site.defaultOccupancyScheduleId) ?
                                    [(<GdCheckbox checked={this.props.overlays.indexOf('OCCUPANCY') !== -1}
                                                  name={'overlayOccupancy'}
                                                  onChange={function () {
                                                      if (self.props.overlays.indexOf('OCCUPANCY') !== -1) {
                                                          self.props.overlays.splice(self.props.overlays.indexOf('OCCUPANCY'), 1);
                                                      } else {
                                                          self.props.overlays.push('OCCUPANCY');
                                                      }
                                                      self.props.setOverlays(self.props.overlays)
                                                  }}
                                                  label={'Occupancy Hours'}/>), (
                                        <OccupancyScheduleSetterMenu ref={this.customKpiMenuRef}
                                                                     site={_site}
                                                                     key={'occupancy-schedule-menu'}
                                                                     onComplete={(res) => {
                                                                         if (self.props.overlays.indexOf('OCCUPANCY') === -1) {
                                                                             self.props.overlays.push('OCCUPANCY');
                                                                         }
                                                                         self.props.setOverlays(self.props.overlays);
                                                                     }}><Icon color={'grey'} size={'9px'}
                                                                              onIconClick={() => {
                                                                              }}
                                                                              icon={'FaCog'}/></OccupancyScheduleSetterMenu>)] :
                                    <OccupancyScheduleSetterMenu ref={this.customKpiMenuRef}
                                                                 site={_site}
                                                                 key={'occupancy-schedule-menu'}
                                                                 onComplete={(res) => {
                                                                     if (self.props.overlays.indexOf('OCCUPANCY') === -1) {
                                                                         self.props.overlays.push('OCCUPANCY');
                                                                     }
                                                                     self.props.setOverlays(self.props.overlays);
                                                                 }}>
                                        <GdCheckbox
                                            checked={this.props.overlays.indexOf('OCCUPANCY') !== -1}
                                            name={'overlayOccupancy'}
                                            label={'Occupancy Hours'}/>
                                    </OccupancyScheduleSetterMenu>}
                            </div>
                        </div>
                    </div>
                </div>)
        }
        let legendWithinDetachedLimit = (legendItems.length && legendItems.length < this.props.detachedLimit) ;
        return (
            <div
                ref={this.props.tooltipRef}
                style={legendWithinDetachedLimit ? {left: this.props.mx + 'px', top: this.props.my + 'px'} : null}
                className={'legend-wrapper' + (this.props.disabled ? ' disabled' : '') + (this.props.zoomData ? ' zoomed' : '') + ((this.props.data && this.props.data.locked) ? ' locked' : '') + (this.state.dateSelection === 'custom' || this.state.isComparing ? ' with-custom' : '') + (legendWithinDetachedLimit ? ' detached ' : '') + (!legendItems.length || (!this.props.showDetached && legendWithinDetachedLimit) ? ' hidden ' : '') + (this.props.graphType === 'heatmap' ? ' animate ' : '')}>

                {/*{overlayRow}*/}

                <div className={'legend-header-wrapper'}>
                    <div
                        onClick={allHidden ? () => this.toggleShowAll(true) : () => this.toggleShowAll(false)}
                        className={'reset-header'}>
                        <div className={'header-text'}>{this.props.displayName}</div>
                        {this.showSubMenu() ?
                            <div className={'reset-button'}>
                                <Tooltip label={allHidden ? 'Show All' : 'Hide All'}>
                                    <div className={'icon-wrapper'}>
                                        <Icon
                                            size={'14'}
                                            color={'gd-dark-grey'}
                                            icon={allHidden ? 'FaEye' : 'FaEyeSlash'}
                                        />
                                    </div>
                                </Tooltip>
                            </div> : null}
                    </div>
                    <div
                        className={'legend-timestamp' + (((this.props.graph === 'line' && this.props.combined) || this.props.graph === 'bar') && tooltipDateCompare && !(this.props.graphType === 'hour_of_day' || this.props.graphType === 'day_of_week') ? ' extended' : '') + (tooltipDate || tooltipDateCompare ? '' : ' hidden')}>
                        {tooltipDate}
                        {tooltipDateCompare}
                    </div>
                </div>
                <div className={'graph-legend-wrapper'}>
                    <div className={className}>
                        {legendItems}
                    </div>
                </div>

            </div>
        )
    }
}

export default Legend;
