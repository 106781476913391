const getDataType = function (type_in) {
    let dataType = "DELIVERED";
    let spendDataType = 'DELIVERED_SPEND';
    type_in = type_in.toLowerCase();
    if (type_in.indexOf('gas') !== -1) {
        dataType += '_GAS'
        spendDataType = 'DELIVERED_GAS_SPEND'
    }
    if (type_in.indexOf('water') !== -1) {
        dataType += '_WATER'
        spendDataType = 'DELIVERED_WATER_SPEND'
    }
    if (type_in.indexOf('hot') !== -1) {
        dataType += '_HOT'
        spendDataType = 'DELIVERED_WATER_HOT_SPEND'
    }
    if (type_in.indexOf('generation') !== -1) {
        dataType = 'GENERATED_DELIVERED';
    }
    return {
        dataType: dataType,
        spendDataType: spendDataType
    }
}

export default getDataType;
