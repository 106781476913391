import React from "react";
import "./widget.scss";
import GridDuck from "gridduck";
import GDGraphs from 'gd-graphs'
import KPIWidget from "./KPIWidget/KPIWidget";
import HistoricWidget from "./HistoricWidget/HistoricWidget";
import DayOfWeekWidget from "./DayOfWeekWidget/DayOfWeekWidget";
import HourOfDayWidget from "./HourOfDayWidget/HourOfDayWidget";
import DeviceBreakdownWidget from "./DeviceBreakdownWidget/DeviceBreakdownWidget";

class Widget extends React.PureComponent {

    existsInString(val, comp) {
        return val.indexOf(comp) !== -1;
    }

    toTitleCase = string => {
        if (string === 'hot_water') {
            return "Hot Water";
        }
        return string.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    constructor(props) {
        super(props);
        this.state = {};
        GDGraphs.config({
            gridduckJS: GridDuck
        });
    }

    render() {
        let id_arr = this.props.id.split('-');
        let col = '#49b9c4', colors = {
            electricity: "#FFCA00",
            gas: "#D768F2",
            water: "#00C3EE",
            hot_water: "#f52ec0",
            generation: "#3ad56e",
            export: '#3ad56e'
        }, icons = {
            electricity: "FaBolt",
            gas: "FaFire",
            water: "FaTint",
            hot_water: "FaTint",
            light: 'FaLightbulb',
            temperature: 'FaThermometerHalf',
            humidity: 'FaWater',
            generation: "FaSolarPanel",
            export: 'FaSolarPanel',
            current: "FaInfinity",
            air_quality: "FaWind",
            signal: "FaBroadcastTower",
            occupancy: "FaGhost"
        }, type = id_arr[0], graph_type = id_arr[1]
        let id = this.props.id;
        console.log(this.props.dataType, ' : this.props.dataType');
        let icon = this.props.dataType?.categoryDetails?.icon || icon;
        let color = this.props.dataType?.categoryDetails?.color || colors[type] || col;
        if (color) {

            //FIXME limit max asset number in widget to 100

            if (graph_type === 'header') {
                this.widget = <KPIWidget
                    isPendingData={this.props.isPendingData}
                    dataType={this.props.dataType}
                    className={this.props.className}
                    reload={this.props.reload}
                    dataItems={this.props.dataItems}
                    item={this.props.item} dateData={this.props.dateData} id={id} unit={<span>kWh</span>}
                    type={this.toTitleCase(type)} filterType={this.props.filterType} filterId={this.props.filterId} color={color}
                    title={this.toTitleCase(type)} icon={icon}/>
            } else if (graph_type === 'historic') {
                this.widget =
                    <HistoricWidget
                        isPendingData={this.props.isPendingData}
                        className={this.props.className}
                        widget={this.props.widget}
                        item={this.props.item} dateData={this.props.dateData} id={id} type={this.toTitleCase(type)}
                        filterType={this.props.filterType}
                        dataItems={this.props.dataItems}
                        filterId={this.props.filterId}
                        icon={icon}
                        color={color}/>;
            } else if (graph_type === 'dow') {
                this.widget =
                    <DayOfWeekWidget
                        isPendingData={this.props.isPendingData}
                        className={this.props.className}
                        widget={this.props.widget}
                        dataItems={this.props.dataItems}
                        item={this.props.item} dateData={this.props.dateData} id={id} type={this.toTitleCase(type)}
                        filterType={this.props.filterType} filterId={this.props.filterId} icon={icon}
                        color={color}/>
            } else if (graph_type === 'spend') {
                this.widget =
                    <KPIWidget
                        isPendingData={this.props.isPendingData}
                        dataType={this.props.dataType}
                        className={this.props.className}
                        widget={this.props.widget}
                        dataItems={this.props.dataItems}
                        item={this.props.item} dateData={this.props.dateData} id={id} unit={<span>£</span>}
                        type={this.toTitleCase(type) + '-spend'} filterType={this.props.filterType} filterId={this.props.filterId} color={color}
                        title={this.toTitleCase(type) + ' Spend'} icon={icon}/>
            } else if (graph_type === 'hod') {
                this.widget =
                    <HourOfDayWidget
                        isPendingData={this.props.isPendingData}
                        className={this.props.className}
                        widget={this.props.widget}
                        dataItems={this.props.dataItems}
                        item={this.props.item} dateData={this.props.dateData} id={id} type={this.toTitleCase(type)}
                        filterType={this.props.filterType} filterId={this.props.filterId} icon={icon}
                        color={color}/>
            } else if (graph_type === 'device_breakdown' || graph_type === 'group_breakdown' || graph_type === 'category_breakdown') {
                this.widget =
                    <DeviceBreakdownWidget
                        isPendingData={this.props.isPendingData}
                        className={this.props.className}
                        widget={this.props.widget}
                        item={this.props.item} graphType={graph_type} dateData={this.props.dateData} id={id}
                        type={this.toTitleCase(type)}
                        dataItems={this.props.dataItems}
                        filterType={this.props.filterType} filterId={this.props.filterId}
                        icon={icon} color={color}/>
            } else {
                this.widget =
                    <HistoricWidget
                        isPendingData={this.props.isPendingData}
                        className={this.props.className}
                        widget={this.props.widget}
                        item={this.props.item} dateData={this.props.dateData} id={id} type={type}
                        title={this.toTitleCase(type)}
                        icon={icon}
                        filterType={this.props.filterType}
                        filterId={this.props.filterId}
                        dataItems={this.props.dataItems}
                        color={color}/>;
            }
        }
        return this.widget || null;
    }

}

export default Widget;
