import React from "react";
import GridDuck from "gridduck";
import {Loader} from 'gd-react'
import './ExampleLogin.scss'
import history from "../meta/history";
import {Redirect} from "react-router-dom";
import cookie from "react-cookies";

class ExampleLogin extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {loggedIn: false};
    }

    componentDidMount() {
        let self = this;
        GridDuck.login('example@gridduck.com', 'duckduck').then(function () {
            let accessToken = GridDuck.config.accessToken;
            delete accessToken.scope;
            cookie.save('accessToken', accessToken, {path: '/'})
            self.setState({loggedIn: true});
        });
    }

    render() {
        if (this.state.loggedIn) {
            return (<Redirect to='/siteGroup/6586d28c-253d-4984-8f43-939840ba99ce'/>);
        }
        return (<div className={"example-login"}>
            <div className={'example-login-inner'}>
                <Loader type={'circular'}/>
                <p>Logging in to Example Account</p>
            </div>
        </div>);
    }
}

export default ExampleLogin;
