import React from 'react';
import GridDuck from 'gridduck';
import BreadcrumbService from "../../meta/breadcrumb-service";
import history from "../../meta/history";
import {Button, DemandListCell, EditableList, Icon, StandardListCell, Toast} from "gd-react";
import TariffModal from "../TariffModal";
import './SiteTariffs.scss'
import NoItems from "../../components/noItems";
import GenericLoader from "../../components/GenericLoader";

class SiteTariffs extends React.Component {
    constructor(props) {
        super(props);
        let {itemId} = this.props.match.params;
        // BreadcrumbService.siteGroup = itemId;
        this.getList = this.getList.bind(this);
        this.openTariffModal = this.openTariffModal.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.loadTariffs = this.loadTariffs.bind(this);
        this.save = this.save.bind(this);

        this.filters = [{
            field: 'siteIds',
            value: [this.props.item.id]
        }];
        let self = this;
        this.state = {
            siteGroupId: itemId
        }
        console.log('site group sites triggered')
    }

    async getList(retrievalParams) {
        retrievalParams.filters = this.filters;
        let tariffs = await GridDuck.getTariffs(retrievalParams);
        if (!this.newTariffList) this.newTariffList = tariffs.list;
        return Promise.resolve(tariffs);
    }

    save(newTariffs) {
        console.log(newTariffs, ' : tariffs');
        this.setState({editing: false});
        return this.props.item.set({tariffIds: newTariffs.map(s => s.id)});
    }

    async loadTariffs() {
        let self = this;
        self.filters = [{
            field: 'siteIds',
            value: [this.props.item.id]
        }];
        self.setState({loaded: false}, () => {
            Promise.all([GridDuck.getTariffs({filters: self.filters}), GridDuck.getTariffs({filters: []})]).then((res) => {
                self.setState({
                    hasTariffs: res[0].list && res[0].list.length,
                    loaded: true,
                    newTariffList: res[0].list,
                    hasAnyTariffs: res[1].list && res[1].list.length
                })
            });
        })
    }

    async componentDidMount() {
        let self = this;
        await this.loadTariffs();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.item && prevProps.item && this.props.item.id !== prevProps.item.id) {
            await this.loadTariffs();
        }
    }

    openTariffModal(tariff) {
        this.setState({showTariffModal: tariff})
    }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async getAvailableList(retrievalParams) {
        await this.timeout(100);
        if (this.newTariffList) {
            let utilityTypes = this.newTariffList.map((ntl) => ntl.utilityType);
            retrievalParams.filters = [{field: 'notUtilityType', value: utilityTypes}];
        }
        let tariffs = await GridDuck.getTariffs(retrievalParams);
        return Promise.resolve(tariffs);
    }

    generateUtilityTypeCell(t) {
        let icon = '';
        let color = ''
        let name = '';

        if (t.utilityType === 'DELIVERED_GAS_SPEND') {
            icon = 'FaFire';
            color = '#D768F2';
            name = 'Gas'
        }
        if (t.utilityType === 'DELIVERED_WATER_SPEND') {
            icon = 'FaWater';
            color = '#00C3EE';
            name = 'Water'
        }
        if (t.utilityType === 'DELIVERED_WATER_HOT_SPEND') {
            icon = 'FaWater';
            color = '#f52ec0';
            name = 'Hot Water'
        }
        if (t.utilityType === 'DELIVERED_SPEND') {
            icon = 'FaBolt';
            color = '#FFCA00';
            name = 'Electricity'
        }
        return (
            <div className={'row'} style={{height: '30px', display: 'flex', alignItems: 'center'}}>
                <div style={{
                    height: '20px',
                    width: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: color,
                    borderRadius: '10px'
                }}>
                    {icon ? <Icon size={'10'} icon={icon} color={'white'}/> : null}
                </div>
                <span style={{marginLeft: '10px'}}>{name}</span>
            </div>

        )

    }

    render() {
        let sites, self = this;

        sites = (
            <EditableList
                onEdit={() => this.setState({editing: true})}
                onCancel={() => {
                    this.newTariffList = null;
                    this.setState({editing: false})
                }}
                isEditing={!this.state.hasTariffs}
                onChange={(newTariffList) => {
                    console.log(newTariffList, ' : new tariff list');
                    this.newTariffList = newTariffList;
                    this.setState({newTariffList: newTariffList, hasTariffs: newTariffList.length})
                }}
                disabled={this.props.item._permission !== 'edit' && this.props.item._permission !== 'admin'}
                editingColumns={[
                    {
                        component: StandardListCell,
                        title: 'Utility Type',
                        field: 'utilityType',
                        custom: this.generateUtilityTypeCell,
                        maxWidth: 'none'
                    }
                ]}
                columns={[
                    {component: StandardListCell, title: 'Name', field: 'name'},
                    {
                        component: StandardListCell,
                        title: 'Utility Type',
                        field: 'utilityType',
                        custom: this.generateUtilityTypeCell
                    },
                    {
                        component: StandardListCell, title: 'Rates', field: 'rates.length',
                        custom: (object) => object.rates ? object.rates.length : '?'
                    },
                    {component: StandardListCell, title: 'Access Level', field: '_permission', maxWidth: 'none'}
                ]}
                getActiveList={this.getList}
                getAvailableList={this.getAvailableList}
                rowOnClick={this.openTariffModal}
                save={this.save}/>
        )
        return (
            <div className='page'>
                {this.state.newTariffList && this.state.newTariffList.length && this.state.editing ?
                    <p className={'tariff-message'}>You can only have one tariff of each utility type per site (remove a
                        current tariff to replace it with one of the same type)</p> : null}
                {this.state.newTariffList && !this.state.newTariffList.length && this.state.editing && this.state.hasTariffs ?
                    <p className={'tariff-message'}>You can only have one tariff of each utility type per site (remove a
                        current tariff to replace it with one of the same type)</p> : null}

                {this.props.loaded && this.state.loaded && this.state.hasAnyTariffs ? sites : null}
                {this.props.loaded && this.state.loaded && !this.state.hasAnyTariffs ? <NoItems
                    text={<div>You haven't created any tariffs yet<br/><Button label={'Create Tariff'}
                                                                               onClick={() => self.setState({openTariff: true})}/>
                    </div>}/> : ''}
                {!this.props.loaded || !this.state.loaded ? <GenericLoader/> : null}
                {(this.state.showTariffModal || this.state.openTariff) ?
                    <TariffModal onSave={(message) => this.setState({showToast: message})}
                                 onClose={() => this.setState({showTariffModal: null, openTariff: false})}
                                 open={!!(this.state.showTariffModal || this.state.openTariff)}
                                 item={this.state.showTariffModal}/> : null}

                <Toast onClose={() => this.setState({showToast: false})} message={this.state.showToast}
                       open={!!(this.state.showToast)}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>
            </div>
        );
    }
}

export default SiteTariffs;