import {StateOptions} from "gd-react";
import * as _ from 'underscore'

const snakeCase = string => {
    return string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
};

const toTitleCase = string => {
    return string
        .split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

const getTypes = function (isAll, checkOne, group) {
    let s_o = StateOptions;
    let all_data_types = [];

    s_o.forEach((so) => {
        all_data_types = all_data_types
            .concat(so.dataTypes)
            .filter(d => !d.downloadOnly)
    })

    let data_types_grouped_by_category = _.groupBy(all_data_types, 'category');
    let possible_widgets = {};

    Object.entries(data_types_grouped_by_category).forEach((t) => {
        let id = snakeCase(t[0]), aggregate = t[1].find(dt => dt.graphType === 'bar');
        if (checkOne) {
            if (checkOne === id) possible_widgets = aggregate;
        } else {
            if (aggregate) {
                possible_widgets[id + '-header'] = {
                    id: id + '-header',
                    name: 'Total',
                    type: id,
                    filter_type: id,
                    data_type: aggregate.type,
                    data_type_obj: aggregate
                }
                if (!isAll) {
                    possible_widgets[id + '-spend'] = {
                        id: id + '-spend',
                        name: 'Spend',
                        type: id,
                        filter_type: id,
                        data_type: aggregate.type,
                    data_type_obj: aggregate
                    }
                }
                if (!isAll) {
                    possible_widgets[id + '-historic'] = {
                        id: id + '-historic',
                        name: 'Historic',
                        type: id,
                        filter_type: id,
                        data_type: aggregate.type,
                    data_type_obj: aggregate
                    }
                }
                possible_widgets[id + '-dow'] = {
                    id: id + '-dow',
                    name: 'Day of Week Average',
                    type: id,
                    filter_type: id,
                    data_type: aggregate.type,
                    data_type_obj: aggregate
                }
                possible_widgets[id + '-hod'] = {
                    id: id + '-hod',
                    name: 'Hour of Day Average',
                    type: id,
                    filter_type: id,
                    data_type: aggregate.type,
                    data_type_obj: aggregate
                }
                if (!isAll) {
                    possible_widgets[id + '-group_breakdown'] = {
                        id: id + '-group_breakdown',
                        name: 'Site Breakdown',
                        type: id,
                        filter_type: id,
                        data_type: aggregate.type,
                    data_type_obj: aggregate
                    }
                    possible_widgets[id + '-device_breakdown'] = {
                        id: id + '-device_breakdown',
                        name: 'Device Breakdown',
                        type: id,
                        filter_type: id,
                        data_type: aggregate.type,
                    data_type_obj: aggregate
                    }
                    possible_widgets[id + '-category_breakdown'] = {
                        id: id + '-category_breakdown',
                        name: 'Category Breakdown',
                        type: id,
                        filter_type: id,
                        data_type: aggregate.type,
                    data_type_obj: aggregate
                    }
                }
            } else {
                if (!isAll && !group) {
                    possible_widgets[id] = {
                        id: id,
                        name: toTitleCase(t[1][0].type),
                        type: id,
                        sensor: true,
                        filter_type: 'sensor',
                        data_type: t[1][0].type,
                        data_type_obj: t[1][0]
                    }
                }
            }
        }
    });

    return possible_widgets;
}

export default getTypes;