import React from "react";
import "../summary.scss";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import * as _ from 'underscore';
import {Icon, Tooltip} from "gd-react";
import Widget from "../Widgets/Widget";
import getTypes from "../../../services/typesFromStateOptionsService";
import moment from "moment";
import GenericLoader from "../../../components/GenericLoader";

class WidgetBoard extends React.Component {

    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.reorder = this.reorder.bind(this);
        this.move = this.move.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.showWidget = this.showWidget.bind(this);
        this.resetLayout = this.resetLayout.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.filterOutMissingTelems = this.filterOutMissingTelems.bind(this);
        this.state = {
            filterOptions: null
        }

        let isAll = this.props.item.id.indexOf('all') !== -1;
        let isGroup = this.props.item.objectTypeName !== 'asset' && this.props.item.objectTypeName !== 'site'

        this.widgets = getTypes(isAll, false, isGroup);
    }

    componentDidMount() {
        console.log(this.props.availableTelemetries, ' available telem widget board')
        let doesntHaveData = !this.props.boardData || !this.props.widgetBoardId;
        this.possibleWidgetsGroupedByType = this.groupPossibleWidgetsByType(this.props.availableTelemetries, this.widgets);
        let cols = this.generateDefaultColumns(doesntHaveData, true);
        // let cols = this.generateDefaultColumns(true, true);
        this.props.onChange(cols, null, () => {
            this.generateFilterMenuDataFromColumns(cols.columnData, doesntHaveData);
        });
    }

    toTitleCase(str) {
        return str
            .split('_')
            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    resetLayout() {
        let columnDataFromTelemetry = this.generateDefaultColumns(true);
        this.props.onChange(columnDataFromTelemetry);
    }

    groupPossibleWidgetsByType(availableTelemetryTypes, widgets) {
        let siteGroup = this.props.siteGroupString;
        let type = this.props.type;
        console.log(siteGroup, ' : groupPossibleWidgetsByType site group');
        console.log(widgets, ' : widgets');
        let PossibleWidgets = Object.entries(_.groupBy(Object.values(widgets), 'filter_type')).map((w) => {
            return {
                type: w[0],
                sensor: w[1][0].sensor,
                possibleWidgets: w[1].filter((wi) => {
                    return (availableTelemetryTypes.find((wf) => this.snakeCase(wf.category.toLowerCase()) === wi.type.toLowerCase())) && ((siteGroup === 'SiteGroup' && wi.id.indexOf('group_breakdown') === -1) || (siteGroup !== 'SiteGroup')) && ((type === 'asset' && wi.id.indexOf('category_breakdown') === -1) || (type !== 'asset'));
                }).map((wii) => wii.id)
            }
        }).filter((wg) => {
            return availableTelemetryTypes.find((wfi) => this.snakeCase(wfi.category.toLowerCase()) === wg.type) || (wg.sensor && wg.possibleWidgets.length)
        });
        console.log(PossibleWidgets, ' : possible widgets grouped by type');

        return PossibleWidgets;
    }

    snakeCase = string => {
        return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join('_');
    };

    allWidgetsOnBoard(columnData) {
        let listOfWidgetsOnBoard = [];
        Object.values(columnData).forEach((cd) => listOfWidgetsOnBoard = listOfWidgetsOnBoard.concat(cd.widget_ids.map((wi) => {
            return this.widgets[wi]
        })));
        return listOfWidgetsOnBoard;
    }

    groupPossibleWidgetsByTypeAndAdjustColumnData(possibleWidgetsGroupedByType, columnData) {
        // Reduce list of possible widgets by removing any that aren't in columnData
        console.log(this.widgets, ' : this.widgets');
        console.log(this.props.availableTelemetries, ' : this.props.availableTelemetries');
        let listOfWidgetsOnBoard = this.allWidgetsOnBoard(columnData).filter(aw => this.props.availableTelemetries.find(at => aw && aw.type.toLowerCase() === this.snakeCase(at.category.toLowerCase())));
        let sortedByOriginalOrder = listOfWidgetsOnBoard.sort((a, b) => Object.values(this.widgets).indexOf(a) - Object.values(this.widgets).indexOf(b));
        let widgetsGroupedByType = _.groupBy(sortedByOriginalOrder, 'filter_type');
        console.log(widgetsGroupedByType, ' : widgets grouped by type');
        return Object.entries(widgetsGroupedByType).map((w) => {
            return {
                type: w[0],
                possibleWidgets: w[1].map((wn) => wn.id)
            }
        });
    }

    generateDefaultColumns(autoLayout, firstTime) {
        //Produce list of available widgets based on list from API
        let self = this;
        let pwgbtarff = this.props.boardData && this.props.boardData.columnData ? this.groupPossibleWidgetsByTypeAndAdjustColumnData(this.possibleWidgetsGroupedByType, this.props.boardData.columnData) : this.possibleWidgetsGroupedByType;
        console.log(JSON.stringify(pwgbtarff, null, '\t'), ' : pwgbtarff');
        // let pwgbtarff = this.possibleWidgetsGroupedByType;
        if (firstTime && autoLayout) {
            let sensor_w = pwgbtarff.find(s => s.type === 'sensor');
            // pwgbtarff = pwgbtarff.filter(p => p.type !== 'generation');
            if (sensor_w && sensor_w.possibleWidgets.length === 1 && sensor_w.possibleWidgets[0] === 'current') {
                pwgbtarff = pwgbtarff.filter(p => p.type !== 'sensor');
            }
            //Filter out generation by default
            pwgbtarff = pwgbtarff.filter(p => p.type !== 'generation');
        }
        let columns = {}, columnOrder = [],
            numberOfCols = autoLayout ? pwgbtarff.length : this.props.boardData.columnOrder.length;
        if (autoLayout) {
            if (numberOfCols < 3) numberOfCols = 3
            for (let i = 1; i <= numberOfCols; i++) {
                let number = Math.random();
                columnOrder.push('column-' + number)
                let widget_ids;
                if (pwgbtarff.length === 1) {
                    let start = (i - 1) * Math.ceil(pwgbtarff[0].possibleWidgets.length / numberOfCols);
                    let end = start ? Math.ceil(start + Math.ceil(pwgbtarff[0].possibleWidgets.length / numberOfCols)) : Math.ceil(pwgbtarff[0].possibleWidgets.length / numberOfCols);
                    widget_ids = pwgbtarff[0].possibleWidgets.slice(start, end);
                } else {
                    widget_ids = pwgbtarff[i - 1] ? pwgbtarff[i - 1].possibleWidgets : [];
                }

                columns['column-' + number] = {
                    id: 'column-' + number,
                    widget_ids: widget_ids
                }
            }
            let is_sensor;
            Object.values(columns).forEach(c => {
                c.widget_ids.forEach(wid => {
                    console.log(this.widgets[wid], ' : this.widgets[wid]');
                    if (this.widgets[wid].filter_type === 'sensor') is_sensor = c.id;
                })
            })
            if (is_sensor) {
                columnOrder = columnOrder.filter(c => c !== is_sensor);
                columnOrder.push(is_sensor);
            }
        } else {
            console.log('Filter out missing telems');
            columns = self.filterOutMissingTelems(this.props.boardData.columnData);
            columnOrder = this.props.boardData.columnOrder;
        }
        let dateData;
        if (!this.props.boardData || !this.props.boardData.dateData) {
            dateData = {
                start: moment().tz(this.props.timezone).subtract(7, 'day').startOf('isoWeek').unix(),
                end: moment().tz(this.props.timezone).startOf('isoWeek').unix(),
                compare_start: moment().tz(this.props.timezone).subtract(2, 'weeks').startOf('isoWeek').unix(),
                compare_end: moment().tz(this.props.timezone).subtract(2, 'weeks').endOf('isoWeek').unix(),
                d_rstring: 'last_week',
                c_rstring: 'previous_week',
                timezone: this.props.timezone
            }
        } else {
            dateData = this.props.boardData.dateData;
        }

        console.log(JSON.stringify(columns, null, '\t'));

        return {
            columnData: columns,
            columnOrder: columnOrder,
            dateData: dateData
        };

    }

    filterOutMissingTelems(columnData) {
        console.log("available telems", this.props.availableTelemetries);
        console.log(this.allWidgetsOnBoard(columnData), "all widgets on board");
        let temp_so = Object.assign({}, columnData);
        let listOfAllowedWidgetsOnBoard = this.allWidgetsOnBoard(columnData).filter(aw => aw && this.props.availableTelemetries.find(at => aw.type.toLowerCase() === this.snakeCase(at.category.toLowerCase()))).map(w => w.id);
        Object.values(columnData).forEach(cd => {
            temp_so[cd.id].widget_ids = cd.widget_ids.filter(wid => listOfAllowedWidgetsOnBoard.find(w => w === wid));
        })
        return temp_so;
    }

    generateFilterMenuDataFromColumns(columnData, showingAll) {
        let listOfWidgetsOnBoard = this.allWidgetsOnBoard(columnData);
        console.log(this.possibleWidgetsGroupedByType, ' : this.possibleWidgetsGroupedByTypeggg');
        let filters = this.possibleWidgetsGroupedByType.map((col) => {
            return {
                id: col.type,
                name: this.toTitleCase(col.type),
                children: col.possibleWidgets.map((wid) => {
                    console.log(wid, ' : wid');
                    console.log(this.widgets[wid], ' : this.widgets[wid]');
                    return {
                        id: wid,
                        name: this.widgets[wid].name,
                        sensor: this.widgets[wid].sensor,
                        parent: col.type,
                        checked: !!(listOfWidgetsOnBoard.find(low => wid === low.id))
                    };
                }).filter((pw) => {
                    return this.props.availableTelemetries.find(at => {
                        return (!pw.sensor || pw.id.toLowerCase() === this.snakeCase(at.category.toLowerCase()));
                    })
                })
            }
        });
        let sensor_filter = filters.find(f => f.id === 'sensor');
        if (sensor_filter) {
            filters = filters.filter(f => f.id !== 'sensor')
            sensor_filter.name = 'Other';
            if (sensor_filter.children && sensor_filter.children.length) {
                filters.push(sensor_filter);
            }
        }
        console.log(JSON.stringify(filters, null, '\t'), ' : filters');
        this.setState({filterOptions: filters});
    }

    showWidget(widget) {
        let isSelected;
        this.props.boardData.filterData.forEach((fd) => {
            let child = fd.children.find((fdc) => {
                return fdc.id === widget.id
            });
            if (child) {
                isSelected = child && child.checked && fd.checked;
            }
        });
        return isSelected;
    }

    colIdFromType(type, asFullString) {
        let typeRes;
        if (type === 'electricity') typeRes = 1;
        if (type === 'gas') typeRes = 2;
        if (type === 'water') typeRes = 3;
        if (type === 'sensor') return 'sensor';
        if (asFullString) return 'column-' + typeRes;
        else return typeRes;
    }

    onChangeFilter(val, item) {
        let temp_so = Object.assign({}, this.props.boardData);
        console.log(item, " : filter item");
        let newFilterOptions = this.state.filterOptions.map((fd) => {
            if (fd.id === item.id) fd.checked = val;
            fd.children.forEach((fdc) => {
                if (fdc.id === item.id || fdc.parent === item.id) fdc.checked = val;
            });
            return fd;
        });
        this.setState({
            filterOptions: newFilterOptions
        }, () => {
            if (!item.children) {
                if (!val) {
                    Object.keys(temp_so.columnData).forEach((cd) => {
                        temp_so.columnData[cd].widget_ids = temp_so.columnData[cd].widget_ids.filter(wi => wi !== item.id);
                    })
                } else {
                    let column_to_push_id = _.sortBy(Object.values(temp_so.columnData), (o) => o.widget_ids.length);
                    console.log(column_to_push_id, ' : column_to_push_id');
                    temp_so.columnData[column_to_push_id[0].id].widget_ids.unshift(item.id);
                }
            } else {
                Object.keys(temp_so.columnData).forEach((cd) => {
                    console.log(cd, ' : cd');
                    temp_so.columnData[cd].widget_ids = temp_so.columnData[cd].widget_ids.filter(wi => {
                        console.log(this.widgets[wi], ' : this.widgets[wi]');
                        console.log(item.id, ' : item.id');
                        return this.widgets[wi].filter_type !== item.id
                    });
                });
                if (val) {
                    let col_data_arr = Object.values(this.props.boardData.columnData);
                    let empty_column = col_data_arr.find(cd => cd.widget_ids.length === 0);
                    let last_column = col_data_arr[col_data_arr.length - 1];
                    let col_id = empty_column ? empty_column.id : last_column.id;
                    console.log('calling grouppossible() ', this.props.availableTelemetries)
                    let possibleWidgets = this.groupPossibleWidgetsByType(this.props.availableTelemetries, this.widgets);
                    temp_so.columnData[col_id].widget_ids = temp_so.columnData[col_id].widget_ids.concat(possibleWidgets.find(pw => {
                        console.log(pw, ' : pw');
                        console.log(item, ' : item');
                        return pw.type === item.id || (pw.sensor && item.id === 'sensor')
                    }).possibleWidgets);
                }
            }

            console.log(JSON.stringify(temp_so, '', '\t'), ' : temp_so');

            if (this.props.onChange) {
                this.props.onChange(temp_so, null, () => {
                    if (item.children) {
                        this.resetLayout();
                    }
                });
            }
        });
    }

    reorder(columnId, startIndex, endIndex) {
        const result = Array.from(this.props.boardData.columnData[columnId].widget_ids);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    move(source, destination, droppableSource, droppableDestination) {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    onDragEnd(result) {
        const {source, destination} = result;
        if (!destination) return;

        let temp_so = Object.assign({}, this.props.boardData);

        const sInd = source.droppableId;
        const dInd = destination.droppableId;

        if (sInd === dInd) {
            temp_so.columnData[dInd].widget_ids = this.reorder(sInd, source.index, destination.index);
        } else {
            let sSind = temp_so.columnData[sInd].widget_ids,
                dSind = temp_so.columnData[dInd].widget_ids;
            const result = this.move(sSind, dSind, source, destination);
            temp_so.columnData[sInd].widget_ids = result[sInd];
            temp_so.columnData[dInd].widget_ids = result[dInd];
        }
        if (this.props.onChange) this.props.onChange(temp_so);
    }

    addColumn() {
        let temp_so = Object.assign({}, this.props.boardData);
        let number = Math.random();
        let columnNoSensor = temp_so.columnOrder;

        columnNoSensor.push('column-' + number);
        temp_so.columnOrder = columnNoSensor;

        temp_so.columnData['column-' + number] = {
            id: 'column-' + number,
            widget_ids: []
        }

        if (this.props.onChange) this.props.onChange(temp_so);
    }

    moveColumn(id, i, direction) {
        let temp_so = Object.assign({}, this.props.boardData);
        if (this.props.onChange) this.props.onChange(temp_so);
        let new_index = i + direction, arr_length = temp_so.columnOrder.length;
        if (new_index < 0) {
            new_index = arr_length
        } else if (new_index >= arr_length) new_index = 0;
        if (new_index > temp_so.columnOrder.length) {
            let k = new_index - arr_length + 1;
            while (k--) {
                temp_so.columnOrder.push(undefined);
            }
        }
        temp_so.columnOrder.splice(new_index, 0, temp_so.columnOrder.splice(i, 1)[0]);
        if (this.props.onChange) this.props.onChange(temp_so);
    }

    removeColumn(id) {
        let temp_so = Object.assign({}, this.props.boardData);
        temp_so.columnOrder = temp_so.columnOrder.filter(co => co !== id);
        let column_to_push_id = _.sortBy(Object.values(temp_so.columnData).filter(cd => cd.id !== id), (o) => o.widget_ids.length);
        temp_so.columnData[column_to_push_id[0].id].widget_ids = temp_so.columnData[column_to_push_id[0].id].widget_ids.concat(temp_so.columnData[id].widget_ids);
        delete temp_so.columnData[id];
        if (this.props.onChange) this.props.onChange(temp_so);
    }

    render() {
        if (this.props.boardData && this.state.filterOptions) {
            let columns = this.props.boardData.columnOrder;
            let col_width = 100 / columns.length;
            return (
                <DragDropContext onDragEnd={this.onDragEnd}>

                    <div style={{display: 'flex', flex: '1 1', overflow: 'auto'}} className={'hidden-scroller'}>
                        {this.props.boardData.columnData ? <div style={{display: 'table', width: '100%'}}>
                            {this.props.boardData.columnOrder.map((columnId, index) => {
                                const column = this.props.boardData.columnData[columnId];
                                const widgets = column.widget_ids.map(taskId => this.widgets[taskId]);
                                console.log(column, ' column')
                                console.log(widgets, ' widgets')
                                return <Droppable key={columnId}
                                                  droppableId={columnId}>
                                    {(provided, snapshot) => (
                                        <div
                                            style={{width: col_width + '%'}}
                                            className={'draggable-column ' + (snapshot.isDraggingOver ? 'dragging-over' : '') + (this.props.editing ? ' is-editing' : '')} {...provided.droppableProps}
                                            ref={provided.innerRef} key={column.id}>
                                            {this.props.editing ? <div className={'editing-header'}>
                                                {this.props.boardData.columnOrder.length >= 2 ?
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <Tooltip label={'Move Column Left'}>
                                                            <div className={'button-wrapper grey'}
                                                                 onClick={() => this.moveColumn(columnId, index, -1)}>
                                                                <Icon
                                                                    size={12}
                                                                    color={'darkgrey'}
                                                                    icon={'FaChevronLeft'}/>
                                                            </div>
                                                        </Tooltip>
                                                        <Tooltip label={'Move Column Right'}>
                                                            <div className={'button-wrapper grey'}
                                                                 style={{marginLeft: '2px'}}
                                                                 onClick={() => this.moveColumn(columnId, index, 1)}>
                                                                <Icon
                                                                    size={12}
                                                                    color={'darkgrey'}
                                                                    icon={'FaChevronRight'}/>
                                                            </div>
                                                        </Tooltip>
                                                    </div> : null}
                                                {this.props.boardData.columnOrder.length >= 2 ?
                                                    <Tooltip label={'Remove Column'}>
                                                        <div className={'button-wrapper'}
                                                             onClick={() => this.removeColumn(columnId)}><Icon size={12}
                                                                                                               color={'darkgrey'}
                                                                                                               icon={'FaEyeSlash'}/>
                                                        </div>
                                                    </Tooltip> : null}
                                            </div> : null}
                                            {widgets.map((widget, index) => {
                                                if (!widget) return
                                                return <Draggable
                                                    key={widget.id}
                                                    isDragDisabled={!this.props.editing}
                                                    draggableId={widget.id}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div ref={provided.innerRef}
                                                             {...provided.draggableProps}>
                                                            <div className={'widget-wrapper'}>
                                                                {this.props.editing ? <div
                                                                    className={'drag-window ' + widget.id} {...provided.dragHandleProps}>
                                                                    <div className={'inner-window'}>
                                                                        <div
                                                                            className={'inner-window-header ' + (this.props.editing ? widget.filter_type : '')}>
                                                                            <p>{!widget.sensor ? this.toTitleCase(widget.type) + ' ' + widget.name : this.toTitleCase(widget.type)}</p>
                                                                            <Tooltip label={'Remove Widget'}>
                                                                                <div className={'button-wrapper'}
                                                                                     onClick={() => this.onChangeFilter(false, widget)}>
                                                                                    <Icon size={12}
                                                                                          color={'darkgrey'}
                                                                                          icon={'FaEyeSlash'}/>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className={'drag-handle'}>
                                                                            <Icon size={'13'} icon={'FaArrowsAlt'}/>
                                                                            <p>Drag
                                                                                to
                                                                                reorder</p>
                                                                        </div>
                                                                    </div>
                                                                </div> : <Widget
                                                                    className={this.props.className}
                                                                    item={this.props.item}
                                                                    key={widget.id}
                                                                    dataType={widget.data_type_obj}
                                                                    isPendingData={this.props.isPendingData}
                                                                    reload={this.props.reload}
                                                                    dateData={this.props.boardData.dateData}
                                                                    widget={widget}
                                                                    dataItems={this.props.dataItems}
                                                                    filterType={this.props.filterType}
                                                                    filterId={this.props.filterId}
                                                                    id={widget.id}/>}

                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </div>)}
                                </Droppable>;
                            })}
                        </div> : null}
                    </div>
                </DragDropContext>
            );
        } else return <GenericLoader/>;
    }
}

export default WidgetBoard;
