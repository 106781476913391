import React from "react";
import {isMobile, isTablet} from "react-device-detect";
import SiteSettingsAccess from "./site/SiteSettingsAccess";
import SiteSettingsDetails from "./site/SiteSettingsDetails";
import {StateOptions} from "gd-react";
import "./site/MainSitePage.scss";
import SiteFilesManager from "./site/SiteFilesManager";
import SiteFamilyTree from "./site/SiteFamilyTree";
import SiteCustomFields from './site/SiteCustomFields';
import TabbedPage from "../components/TabbedPage/TabbedPage";
import DesktopSiteAssetListPage from "./site/DesktopSiteAssetListPage";
import DesktopDataPage from "./data/DesktopDataPage";
import AuditListPage from "./site/audit/AuditListPage";
import DesktopSiteSettingsGateways from "./site/DesktopSiteSettingsGateways";
import DesktopSummaryPage from "./Summary/DesktopSummaryPage";
import DesktopSiteSettingsUnusedGateways from "./site/DesktopSiteSettingsUnusedGateways";
import SiteTariffs from "./site/SiteTariffs";
import SiteSettingsPhaseAudit from "./site/SiteSettingsPhaseAudit";
import history from "../meta/history";
import GridDuck from 'gridduck';
import DesktopSiteGroupSites from "./sitegroup/DesktopSiteGroupSites";
import SiteGroupSettings from "./sitegroup/SiteGroupSettings";
import GenericLoader from "../components/GenericLoader";
import GroupAssetList from "./group/GroupAssetList";
import GroupSettings from "./group/GroupSettings";
import DesktopAssetOverviewPage from "./asset/DesktopAssetOverviewPage";
import AssetSettingsDetails from "./asset/AssetSettingsDetails";
import AssetSettingsEventLog from "./asset/AssetSettingsEventLog";
import AssetAdmin from "./asset/AssetAdmin";
import AssetAdminReset from "./asset/AssetAdminReset";
import AssetAdminExistingResets from "./asset/AssetAdminExistingResets";
import AssetAdminCustomResets from "./asset/AssetAdminCustomResets";
import GatewayOverviewPage from "./gateway/GatewayOverviewPage";
import GatewaySettings from "./gateway/GatewaySettings";
import GatewaySettingsEventLog from "./gateway/GatewaySettingsEventLog";
import GatewayOps from "./gateway/GatewayOps";
import GatewayZigbee from "./gateway/GatewayZigbee";
import GatewayAdmin from "./gateway/GatewayAdmin";
import ContextBar from "../components/ContextBar";
import ReactDOM from "react-dom";
import IssuePopover from "../components/IssuePopover/IssuePopover";
import Icon from "../components/Icon/Icon";
import MobileDataPage from "./data/MobileDataPage";
import MobileSiteGroupSites from "./sitegroup/MobileSiteGroupSites";
import MobileSiteAssetListPage from "./site/MobileSiteAssetListPage";
import MobileSiteSettingsGateways from "./site/MobileSiteSettingsGateways";
import OrganisationMembers from "./organisation/OrganisationMembersPage";
import UserDrawer from "../components/Drawer/UserDrawer";
import PartnerOrgDrawer from "../components/Drawer/PartnerOrgDrawer";

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: null,
            loaded: false,
            contextItems: null
        };

        [
            'getSiteData',
            'loadSiteTabs',
            'loadSiteGroupTabs',
            'loadClientTabs',
            'loadDeviceGroupTabs',
            'getTypeAndId',
            'loadTabs',
            'getSiteGroupData',
            'getClientData',
            'getDeviceGroupData',
            'gatewaysUpdated',
            'getData',
            'getDeviceData',
            'getHubData',
            'loadDeviceTabs',
            'loadHubTabs',
            'accountUpdated',
            'hubUpdated',
            'updateGroupFilter',
            'removeUnusedDataTypes'
        ].forEach(f => this[f] = this[f].bind(this));

        console.log('Load main page');
    }

    accountUpdated() {
        this.setState({user: this.user});
    }

    getTypeAndId(props) {
        let returnObj = {};
        if (props) {
            let {itemType} = props.match.params;
            let {itemId} = props.match.params;
            returnObj.itemId = itemId;
            returnObj.item = itemType;
            if (itemType === 'site') {
                this.filterType = 'site_id'
            } else if (itemType === 'siteGroup') {
                this.filterType = 'site_group_id'
            } else if (itemType === 'deviceGroup') {
                this.filterType = 'group_id'
            } else if (itemType === 'device') {
                this.filterType = 'asset_id'
            } else if (itemType === 'hub') {
                this.filterType = 'asset_id'
            } else if (itemType === 'client') {
                this.filterType = 'org_partner_id'
            }
        }

        return returnObj;
    }

    getData() {
        let type = this.getTypeAndId(this.props);
        if (type.item === 'site') {
            this.getSiteData();
        } else if (type.item === 'siteGroup') {
            this.getSiteGroupData();
        } else if (type.item === 'deviceGroup') {
            this.getDeviceGroupData();
        } else if (type.item === 'device') {
            this.getDeviceData();
        } else if (type.item === 'hub') {
            this.getHubData();
        } else if (type.item === 'client') {
            console.log('Load site group data');
            this.getClientData();
        } else {
            history.push('/siteGroup/all');
        }
    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                self.user = user;
                self.setState({
                    user: user,
                    orgPermission: user.orgPermission
                }, self.getData);
                self.user.on('updated', self.accountUpdated);
            });
    }

    componentWillUnmount() {
        let self = this;
        if (this.state.hubs && this.state.hubs.off) {
            this.state.hubs.off('updated', this.gatewaysUpdated);
            this.hubs.list.forEach(function (hub) {
                if (hub.off) {
                    hub.off('updated', self.hubUpdated);
                }
            });
        }
    }

    hubUpdated(key, value) {
        if (key === 'disconnected') {
            this.setState({hubs: this.hubs});
        }
    }

    gatewaysUpdated() {
        let self = this;
        this.setState({
            hubs: this.hubs
        });
        this.hubs.list.forEach(function (hub) {
            if (hub.off) {
                hub.off('updated', self.hubUpdated);
            }
            if (hub.on) {
                hub.on('updated', self.hubUpdated);
            }
        })
    }

    async getDeviceGroupData() {
        this.setState({
            loaded: false
        })
        let {itemId} = this.props.match.params;
        let self = this;
        let group = await GridDuck.getGroup({
            id: itemId
        })
        let assets = await GridDuck.getAssets({
            filters: [
                {
                    field: 'id',
                    value: group.assetIds
                }
            ]
        })

        let allDataTypes = []
        for (const a of assets.list) {
            let stateOptions = StateOptions.find(t => t.type === a.sku)
            if (!stateOptions) {
                stateOptions = StateOptions.find(t => t.type === 'default');
            }
            let dataTypes = self.removeUnusedDataTypes(a.loadType, stateOptions.dataTypes)
            for (const dt of dataTypes) {
                if (!allDataTypes.find((t) => (dt.type === t.type))) {
                    allDataTypes.push(dt)
                }
            }
        }

        let state = {
            item: group,
            loaded: true,
            type: 'deviceGroup',
            pageClass: 'group-page',
            filterType: 'group_id',
            assets: assets.list,
            availableDataTypes: allDataTypes
        };
        state.listFilters = [{
            field: 'id',
            value: group.assetIds
        }]
        self.setState(state);
        group.on('updated', self.updateGroupFilter);

    }

    async getHubData() {
        let self = this;
        let {itemId} = this.props.match.params;
        this.itemId = itemId;
        let gw_acc = await Promise.all([
            GridDuck.getGateway({
                id: itemId
            }),
            GridDuck.getAccount({id: GridDuck.userId}),
            GridDuck.getAssets({
                items: 1,
                filters: [{
                    "field": 'gatewayId',
                    "value": itemId
                }]
            }),
        ]);
        let vfgw;

        let gateway = gw_acc[0];
        if (gateway.virtualFrom) {
            vfgw = await GridDuck.getGateway({id: gateway.virtualFrom});
        }
        let account = gw_acc[1];

        self.setState({
            item: gateway,
            loaded: true,
            type: 'hub',
            filterType: 'asset_id',
            pageClass: 'site-page',
            virtualGateway: vfgw,
            deviceCount: gw_acc[2].total
        });
        self.setState({
            account: account
        });
    }

    removeUnusedDataTypes(loadType, dataTypes) {
        let filteredDataTypes;
        switch (loadType) {
            case 0: {
                filteredDataTypes = dataTypes.filter((dt) => dt.type !== 'GENERATED_DELIVERED' && dt.type !== 'GENERATED_CONSUMPTION' && dt.type !== 'EXPORTED_CONSUMPTION' && dt.type !== 'EXPORTED_DELIVERED')
            }
                break;
            case 1: {
                filteredDataTypes = dataTypes.filter((dt) => dt.type !== 'EXPORTED_DELIVERED' && dt.type !== 'EXPORTED_CONSUMPTION')
            }
                break
            case 2: {
                filteredDataTypes = dataTypes.filter((dt) => dt.type !== 'GENERATED_DELIVERED' && dt.type !== 'GENERATED_CONSUMPTION')
            }
                break
            default:
                filteredDataTypes = dataTypes.filter((dt) => dt.type !== 'GENERATED_DELIVERED' && dt.type !== 'GENERATED_CONSUMPTION' && dt.type !== 'EXPORTED_CONSUMPTION' && dt.type !== 'EXPORTED_DELIVERED')
                break
        }
        return filteredDataTypes
    }

    updateGroupFilter(field, value) {
        if (field === 'assetIds') {
            this.setState({
                listFilters: [{
                    field: 'id',
                    value: value
                }]
            });
        }
    }

    getDeviceData() {
        console.log('get device data running ')
        let self = this;
        let {itemId} = this.props.match.params;
        self.setState({
            loaded: false
        });
        GridDuck.getAsset({
            id: itemId
        }).then(function (asset) {
            console.log(asset.loadType, ' asset in getdevice data')
            GridDuck.getSite({id: asset.groupIds[0]}).then((site) => {
                let stateOptions = StateOptions.find(t => t.type === asset.sku);
                if (!stateOptions) {
                    stateOptions = StateOptions.find(t => t.type === 'default');
                }

                let dataTypes = self.removeUnusedDataTypes(asset.loadType, stateOptions.dataTypes)
                self.setState({
                    item: asset,
                    site: site,
                    type: 'asset',
                    pageClass: 'asset-page',
                    filterType: 'asset_id',
                    availableDataTypes: dataTypes,
                    loading: false,
                    loaded: true
                });
            });
        })
    }

    async getSiteData() {
        let {itemId} = this.props.match.params;
        let self = this;
        self.setState({
            loaded: false
        });
        let site = await GridDuck.getSite({id: itemId});
        let data = await Promise.all([
            GridDuck.getAssets({
                filters: [
                    {
                        field: 'siteId',
                        value: site.id
                    }
                ],
                getAll: true
            }),
            GridDuck.getGateways({
                filters: [
                    {
                        field: 'siteId',
                        value: site.id
                    },
                    {
                        field: 'locationId',
                        value: site.locationId
                    }
                ],
                getAll: true
            }),
        ]);
        let allDataTypes = []
        for (const a of data[0].list) {
            let stateOptions = StateOptions.find(t => t.type === a.sku)
            console.log(stateOptions, ' : state options');
            if (!stateOptions) {
                stateOptions = StateOptions.find(t => t.type === 'default');
            }
            let dataTypes = self.removeUnusedDataTypes(a.loadType, stateOptions.dataTypes)
            for (const dt of dataTypes) {
                if (!allDataTypes.find((t) => (dt.type === t.type))) {
                    allDataTypes.push(dt)
                }
            }
        }
        let hubsList = data[1];
        self.loading = false;
        self.hubs = hubsList;
        self.hubs.list.forEach(function (hub) {
            if (hub.on) {
                hub.on('updated', self.hubUpdated);
            }
        });
        hubsList.on('updated', self.gatewaysUpdated);
        let list = data[0].list;
        let siteState = {
            assets: list,
            deviceCount: data[0].total,
            item: site,
            hubs: hubsList,
            hubCount: hubsList.total,
            type: 'site',
            pageClass: 'site-page',
            filterType: 'site_id',
            availableDataTypes: allDataTypes,
            loading: false,
            loaded: true
        };
        if (itemId === site.id) {
            self.setState(siteState);
        } else {
            console.log("Not valid data loaded");
        }
    }


    async getClientData() {
        console.log("MainPage Get Client Data");
        let self = this;
        let {itemId} = this.props.match.params;

        self.setState({
            loaded: false
        })

        let orgPartner = await GridDuck.getOrgPartner({id: itemId});
        console.log("MainPage Org Partner", orgPartner);
        let allDataTypes = [];
        let availableDataTypes = [];
        StateOptions.forEach(function (sO) {
            sO.dataTypes.forEach(function (sDT) {
                if (!allDataTypes.find(dT => dT.type === sDT.type)) {
                    allDataTypes.push(sDT);
                }
            });
        })
        orgPartner.dataTypes.forEach(function (dataTypeName) {
            let dataType = allDataTypes.find(dT => dT.type === dataTypeName)
            if (!availableDataTypes.find(dT => dT.type === dataType.type)) {
                if (dataType.category !== 'Signal') {
                    availableDataTypes.push(dataType);
                }
            }
        });
        let filters = [{field: "orgPartnerId", value: itemId}];
        let state = {
            item: orgPartner,
            availableDataTypes: availableDataTypes,
            filterType: 'org_partner_id',
            type: 'client',
            pageClass: 'client-page',
            listFilters: filters
        };
        state.contextItems = [];
        // Get Hub count TODO Add to get site group end point

        let _tabData = await Promise.all([
            GridDuck.getAssets({items: 1, filters: filters}),
            GridDuck.getGateways({items: 1, filters: filters}),
            GridDuck.getSites({items: 1, filters: filters}),
            GridDuck.getOrganisationMembers({items: 1, filters: filters})
        ])

        let devices = _tabData[0];
        let gateways = _tabData[1];
        let sites = _tabData[2];
        let members = _tabData[3]

        state.hubCount = gateways.total;
        state.deviceCount = devices.total;
        state.siteCount = sites.total;
        state.memberCount = members.total;
        console.log("MainPage Client Data", state);
        self.setState(state, () => self.setState({loaded: true}));
        // siteGroup.on('updated', self.updateFilter);
    }

    async getSiteGroupData() {
        let self = this;
        let {itemId} = this.props.match.params;
        self.setState({
            loaded: false
        })
        if (itemId === 'all') {
            history.push('/siteGroup/all_' + self.state.user.id + '_' + self.state.user.organisationId);
            return;
        }

        let type = this.getTypeAndId(this.props);
        let isAll = type.itemId.substr(0, 3) === 'all';

        let availableDataTypes = []
        let allDataTypes = []

        let siteGroup = await GridDuck.getSiteGroup({id: itemId});
        // fixme remove when optimised in the api for datatypes per asset / site
        if (isAll) {
            StateOptions.forEach(function (sO) {
                sO.dataTypes.forEach(function (sDT) {
                    if (!allDataTypes.find(dT => dT.type === sDT.type)) {
                        allDataTypes.push(sDT);
                    }
                });
            })
            siteGroup.dataTypes.forEach(function (dataTypeName) {
                let dataType = allDataTypes.find(dT => dT.type === dataTypeName)
                if (dataType && !availableDataTypes.find(dT => dT.type === dataType.type)) {
                    if (dataType.category !== 'Signal') {
                        availableDataTypes.push(dataType);
                    }
                }
            });
        } else {
            let assetsRes = await Promise.allSettled(siteGroup.siteIds.map((id) => {
                return GridDuck.getAssets({
                    filters: [
                        {
                            field: 'siteId',
                            value: id
                        }
                    ]
                })
            }));
            let assets = assetsRes.filter(result => result.status === 'fulfilled')
                .map(result => result.value).map((list) => list.list).flat()
            console.log(assets, ' : assets');
            for (const a of assets) {
                if (a) {
                    let stateOptions = StateOptions.find(t => t.type === a.sku)
                    if (!stateOptions) {
                        stateOptions = StateOptions.find(t => t.type === 'default');
                    }
                    let dataTypes = self.removeUnusedDataTypes(a.loadType, stateOptions.dataTypes)
                    for (const dt of dataTypes) {
                        if (!allDataTypes.find((t) => (dt.type === t.type))) {
                            allDataTypes.push(dt)
                        }
                    }
                }
            }
        }


        let state = {
            item: siteGroup,
            availableDataTypes: allDataTypes,
            filterType: 'site_group_id',
            type: 'SiteGroup',
            pageClass: 'site-group-page'
        };
        state.contextItems = [];
        if (itemId !== 'all_' + self.state.user.id + '_' + self.state.user.organisationId) {
            state.listFilters = [{
                field: 'id',
                value: siteGroup.siteIds
            }]
        } else {
            state.listFilters = [];
        }
        let filters;
        if (itemId.indexOf('all') === -1) {
            filters = [{field: "siteGroupId", value: itemId}];
        }
        // Get Hub count TODO Add to get site group end point
        let _tabData = await Promise.all([
            GridDuck.getAssets({items: 1, filters: filters}),
            GridDuck.getGateways({items: 1, filters: filters})
        ]);
        let devices = _tabData[0];
        let gateways = _tabData[1];

        state.hubCount = gateways.total;
        state.deviceCount = devices.total;
        self.setState(state, () => self.setState({loaded: true}));
        // siteGroup.on('updated', self.updateFilter);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevType = this.getTypeAndId(prevProps);
        let type = this.getTypeAndId(this.props);
        if (prevType.itemId && type.itemId && prevType.itemId !== type.itemId) {
            this.getData();
        }
    }

    loadDeviceTabs() {
        let self = this;
        let dataTabs = [];
        let tabs;
        if (this.state.item && this.state.availableDataTypes) {
            this.state.availableDataTypes.filter(dT => !dT.downloadOnly)
                .forEach(function (dataType) {
                    if ((dataType.type.toLowerCase().indexOf('spend') === -1)) {
                        dataTabs.push({
                            default: dataTabs.length === 0,
                            hideRow: true,
                            url: dataType.type.toLowerCase(),
                            priority: self.dataTypePriority(dataType),
                            icon: dataType.categoryDetails?.icon,
                            color: dataType.categoryDetails?.color || window._brandColor,
                            title: dataType.name,
                            dataType: dataType,
                            component: isMobile ? MobileDataPage : DesktopDataPage,
                        })
                    }
                })

            dataTabs = dataTabs.sort((da, db) => db.priority - da.priority);

            tabs = [
                {
                    default: true,
                    url: 'summary',
                    title: 'Summary',
                    component: DesktopSummaryPage
                },
                {
                    url: 'data',
                    default: isMobile,
                    title: 'Data',
                    hideChildren: true,
                    tabs: dataTabs
                }, {
                    url: 'details',
                    title: 'Details',
                    component: DesktopAssetOverviewPage,
                    tabs: []
                },
                {
                    url: 'settings',
                    title: 'Settings',
                    tabs: [
                        {
                            default: true,
                            url: 'details',
                            title: 'Details',
                            component: AssetSettingsDetails
                        },
                        {
                            url: 'events',
                            title: 'Event Log',
                            component: AssetSettingsEventLog
                        }
                    ]
                }
            ];
            if (this.state.user && this.state.user.god) {
                tabs.push(
                    {
                        url: 'admin',
                        title: 'Admin',
                        tabs: [
                            {
                                default: true,
                                url: 'ops',
                                title: 'Ops',
                                component: AssetAdmin
                            },
                            {
                                url: 'resets',
                                title: 'Data Resets',
                                tabs: [
                                    {
                                        url: 'scan',
                                        title: 'Scan',
                                        component: AssetAdminReset,
                                        default: true
                                    },
                                    {
                                        url: 'existing',
                                        title: 'Existing',
                                        component: AssetAdminExistingResets,
                                    },
                                    {
                                        url: 'custom',
                                        title: 'Custom',
                                        component: AssetAdminCustomResets,
                                    }
                                ]
                            }
                        ]
                    })
            }
        }

        if (isMobile && tabs) {
            tabs = tabs.filter((t) => t.title !== 'Summary');
        }

        return tabs;
    }


    dataTypePriority(dataType) {
        let category = dataType.category.toLowerCase();
        let priority = 0;
        let priorityArray = [
            () => category === 'electricity' && dataType.dataType === 'aggregate',
            () => category === 'gas',
            () => category === 'water',
            () => category === 'hot water',
            () => category === 'generation',
            () => category === 'temperature',
            () => category === 'humidity'
        ];
        priorityArray.forEach((f, i) => {
            if (f()) {
                priority = priorityArray.length - i;
            }
        });
        return priority;
    }

    loadSiteTabs() {
        let self = this;
        let dataTabs = [];
        let settingsTabs = [
            {
                default: true,
                url: 'details',
                title: 'Details',
                component: SiteSettingsDetails
            }, {
                url: 'custom-fields',
                title: 'Custom Fields',
                component: SiteCustomFields
            }, {
                url: 'tariffs',
                title: 'Tariffs',
                component: SiteTariffs
            }
        ];
        if (this.state.item && this.state.assets) {
            this.state.availableDataTypes
                .filter(dT => !dT.downloadOnly)
                .forEach(function (dataType) {
                    let tabObj = {
                        url: dataType.type.toLowerCase(),
                        title: dataType.name,
                        hideRow: true,
                        priority: self.dataTypePriority(dataType),
                        dataType: dataType,
                        icon: dataType.categoryDetails?.icon,
                        color: dataType.categoryDetails?.color || window._brandColor,
                        component: DesktopDataPage
                    };

                    if (dataType.category !== 'Signal' && (dataType.type.toLowerCase().indexOf('spend') === -1)) {
                        dataTabs.push(tabObj)
                    }
                    // else if (dataType.category === 'Signal' && (!settingsTabs.find(dT => dT.title === dataType.category))) {
                    //     settingsTabs.push(tabObj)
                    // }

                })
        }

        if (this.state.user && !this.state.user.inDemo) settingsTabs.push({
            url: 'access',
            title: 'Access',
            component: SiteSettingsAccess
        })
        if (this.state.user && this.state.user.god) settingsTabs.push({
            url: 'phaseAudit',
            title: 'Phase Audit',
            component: SiteSettingsPhaseAudit
        })
        let tabs;
        if (!this.state.assets || this.state.assets.length > 0) {
            dataTabs.sort((dTA, dTB) => dTB.priority - dTA.priority);
            if (dataTabs.length > 0) {
                dataTabs[0].default = true;
            }
            tabs = [
                {
                    default: true,
                    url: 'summary',
                    title: 'Summary',
                    component: DesktopSummaryPage
                },
                {
                    url: 'data',
                    hideChildren: true,
                    title: 'Data',
                    tabs: dataTabs
                }
            ];
        } else {
            tabs = [];
        }
        tabs.push(
            {
                url: 'devices',
                title: 'Devices',
                count: this.state.deviceCount,
                default: this.state.assets && this.state.assets.length === 0 || isMobile,
                component: isMobile ? MobileSiteAssetListPage : DesktopSiteAssetListPage
            }, {
                url: 'hubs',
                count: this.state.hubCount,
                title: 'Hubs',
                tabs: [
                    {
                        url: 'in-site',
                        default: true,
                        icon: 'FaBuilding',
                        title: 'In Site',
                        component: isMobile ? MobileSiteSettingsGateways : DesktopSiteSettingsGateways
                    },
                    {
                        url: 'all',
                        title: 'All Hubs',
                        component: isMobile ? MobileSiteSettingsGateways : DesktopSiteSettingsUnusedGateways
                    }]
            },
            {
                url: 'files',
                count: this.state.fileCount,
                title: 'Files',
                component: SiteFilesManager
            });
        if (this.state.item && this.state.item._permission === 'admin') {
            settingsTabs.push({
                url: 'family',
                title: 'Family Tree',
                component: SiteFamilyTree
            })
            tabs.push(
                {
                    url: 'audits',
                    title: 'Audits',
                    component: AuditListPage
                }
            )
        }
        tabs.push({
            url: 'settings',
            title: 'Settings',
            tabs: settingsTabs
        })
        if (isMobile && tabs) {
            tabs = tabs.filter((t) => t.title !== 'Summary' && t.title !== 'Files' && t.title !== 'Audits');
        }
        if (tabs) {
            console.log(tabs, ' : site tabs');
            return tabs;
        }
    }

    loadSiteGroupTabs() {
        let self = this;
        let type = this.getTypeAndId(this.props);
        let isAll = type.itemId.substr(0, 3) === 'all';
        let dataTabs = [];
        let allDataTypes = [];

        if (this.state.item && isAll && this.state.item.siteIds && this.state.item.siteIds.length === 1) {
            history.push('/site/' + this.state.item.siteIds[0]);
            return null;
        }
        if (this.state.item && this.state.item.dataTypes) {
            this.state.availableDataTypes.forEach(function (dataType) {
                if (dataType && (dataType.type.toLowerCase().indexOf('spend') === -1)) {
                    dataTabs.push({
                        default: false,
                        url: dataType.type.toLowerCase(),
                        title: dataType.name,
                        category: dataType.category,
                        hideRow: true,
                        priority: self.dataTypePriority(dataType),
                        icon: dataType.categoryDetails?.icon,
                        color: dataType.categoryDetails?.color || window._brandColor,
                        dataType: dataType,
                        component: isMobile ? MobileDataPage : DesktopDataPage
                    })
                }
            });
            dataTabs = dataTabs.sort((da, db) => db.priority - da.priority);
            if (isAll) {
                dataTabs = dataTabs.filter(dt => (dt.category === 'Electricity' || dt.category === 'Gas' || dt.category === 'Water' || dt.category === 'Hot Water' || dt.category === 'Generation') && dt.dataType.dataType === 'aggregate')
            }
            if (dataTabs && dataTabs.length) {
                dataTabs[0].default = true;
            }
            console.log(dataTabs, ' : data tabs');

        }

        let tabs;

        if (this.state.availableDataTypes && this.state.availableDataTypes.length) {
            tabs = [
                {
                    default: true,
                    url: 'summary',
                    title: 'Summary',
                    component: DesktopSummaryPage
                },
                {
                    url: 'data',
                    hideChildren: true,
                    title: 'Data',
                    tabs: dataTabs
                },
                {
                    url: 'sites',
                    title: 'Sites',
                    default: isMobile,
                    count: this.state.item?.siteIds?.length,
                    component: isMobile ? MobileSiteGroupSites : DesktopSiteGroupSites
                },
                {
                    url: 'devices',
                    title: 'Devices',
                    count: this.state.deviceCount,
                    component: isMobile ? MobileSiteAssetListPage : DesktopSiteAssetListPage
                    //     DesktopSiteGroupAssetListPage
                },
                {
                    url: 'hubs',
                    title: 'Hubs',
                    count: this.state.hubCount,
                    component: isMobile ? MobileSiteSettingsGateways : DesktopSiteSettingsUnusedGateways
                }
            ];


            if (!isAll && (this.state.item && this.state.item._permission === 'admin')) {
                tabs.push(
                    {
                        url: 'settings',
                        title: 'Settings',
                        component: SiteGroupSettings
                    }
                )
            }
        }
        if (isMobile && tabs) {
            tabs = tabs.filter((t) => t.title !== 'Summary')
        }

        return tabs;
    }

    loadClientTabs() {
        let self = this;
        let type = this.getTypeAndId(this.props);

        let dataTabs = [];
        let allDataTypes = [];
        StateOptions.forEach(function (sO) {
            sO.dataTypes.forEach(function (sDT) {
                if (!allDataTypes.find(dT => dT.type === sDT.type)) {
                    allDataTypes.push(sDT);
                }
            });
        })

        if (this.state.item && this.state.item.dataTypes) {
            this.state.item.dataTypes.forEach(function (dataTypeName) {
                let dataType = allDataTypes.find(dT => dT.type === dataTypeName)
                if (dataType && (dataType.type.toLowerCase().indexOf('spend') === -1)) {
                    dataTabs.push({
                        default: false,
                        url: dataType.type.toLowerCase(),
                        hideRow: true,
                        title: dataType.name,
                        priority: self.dataTypePriority(dataType),
                        icon: dataType.categoryDetails?.icon,
                        color: dataType.categoryDetails?.color || window._brandColor,
                        dataType: dataType,
                        component: isMobile ? MobileDataPage : DesktopDataPage
                    })
                }
            });
            dataTabs = dataTabs.sort((da, db) => db.priority - da.priority);
            if (dataTabs && dataTabs.length) {
                dataTabs[0].default = true;
            }

        }

        // dataTabs = dataTabs.sort((dTA, dTB) => dTB.priority - dTA.priority);

        let tabs;

        if (this.state.availableDataTypes && this.state.availableDataTypes.length) {
            tabs = [
                {
                    id: 1,
                    default: true,
                    url: 'summary',
                    title: 'Summary',
                    component: DesktopSummaryPage
                },
                {
                    id: 3,
                    url: 'data',
                    hideChildren: true,
                    title: 'Data',
                    tabs: dataTabs
                },
                {
                    id: 2,
                    url: 'members',
                    title: 'Members',
                    count: this.state.memberCount,
                    component: OrganisationMembers
                },
                {
                    id: 4,
                    url: 'sites',
                    title: 'Sites',
                    default: isMobile,
                    count: this.state.siteCount,
                    component: isMobile ? MobileSiteGroupSites : DesktopSiteGroupSites
                },
                {
                    id: 5,
                    url: 'devices',
                    title: 'Devices',
                    count: this.state.deviceCount,
                    component: isMobile ? MobileSiteAssetListPage : DesktopSiteAssetListPage
                    //     DesktopSiteGroupAssetListPage
                },
                {
                    id: 6,
                    url: 'hubs',
                    title: 'Hubs',
                    count: this.state.hubCount,
                    component: isMobile ? MobileSiteSettingsGateways : DesktopSiteSettingsUnusedGateways
                },
                {
                    id: 7,
                    url: 'settings',
                    title: 'Settings',
                    onTabClick: async (e) => {
                        console.log(e, ' : e settings');
                        e.stopPropagation();
                        e.preventDefault();
                        let {itemId} = this.props.match.params;
                        let item = await GridDuck.getOrgPartner({id: itemId});
                        this.setState({openClientSettings: item});
                    }
                }
            ];

            // TODO Create Client Settings
            // if ((this.state.item && this.state.item._permission === 'admin')) {
            //     tabs.push(
            //         {
            //             url: 'settings',
            //             title: 'Settings',
            //             component: SiteGroupSettings
            //         }
            //     )
            // }
        }
        if (isMobile && tabs) {
            tabs = tabs.filter((t) => t.title !== 'Summary')
        }

        return tabs;
    }

    loadDeviceGroupTabs() {
        let self = this;
        let dataTabs = [];
        let tabs;
        if (this.state.item && this.state.assets) {
            this.state.assets.forEach(function (asset) {
                let stateOptions = StateOptions.find(t => t.type === asset.sku);
                if (!stateOptions) {
                    stateOptions = StateOptions.find(t => t.type === 'default');
                }
                if (stateOptions) {
                    stateOptions.dataTypes.forEach(function (dataType) {
                        if (!dataTabs.find(dT => dT.title === dataType.category) && (dataType.type.toLowerCase().indexOf('spend') === -1)) {
                            dataTabs.push({
                                default: dataTabs.length === 0,
                                url: dataType.type.toLowerCase(),
                                hideRow: true,
                                title: dataType.name,
                                icon: dataType.categoryDetails?.icon,
                                color: dataType.categoryDetails?.color || window._brandColor,
                                priority: self.dataTypePriority(dataType),
                                dataType: dataType,
                                component: DesktopDataPage
                            })
                        }
                    })
                }
            })


            dataTabs = dataTabs.sort((dTA, dTB) => dTB.priority - dTA.priority);

            tabs = [
                {
                    url: 'data',
                    hideChildren: true,
                    title: 'Data',
                    tabs: dataTabs
                },
                {
                    url: 'devices',
                    title: 'Devices',
                    component: GroupAssetList
                },
                {
                    url: 'settings',
                    title: 'Settings',
                    component: GroupSettings
                }
            ];

            if (!tabs.find(t => t.url === 'summary')) {
                tabs.unshift({
                    default: true,
                    url: 'summary',
                    title: 'Summary',
                    component: DesktopSummaryPage
                })
            }
        }
        return tabs;
    }

    loadHubTabs() {
        let dataTabs = [
            {
                default: true,
                url: 'wifi_signal',
                title: 'WiFi Signal Strength',
                dataType: {
                    type: 'GATEWAY_WIFI_STRENGTH',
                    category: 'WiFi Signal Strength',
                    label: 'WiFi Signal Strength',
                    unit: 'dB',
                    graphType: 'line',
                    assetType: 'gateway'
                },
                component: DesktopDataPage
            }
        ];
        if (this.state.item && (this.state.item.modelNumber === 'MGW211-DP27' || this.state.item.modelNumber === 'MGW211-DP27A' || this.state.item.modelNumber === 'MGW101-DP07')) {
            dataTabs = dataTabs.concat([
                {
                    url: 'mobile_signal',
                    title: 'Mobile Signal Quality',
                    dataType: {
                        type: 'GATEWAY_MOBILE_STRENGTH',
                        category: 'GATEWAY_MOBILE_STRENGTH',
                        label: 'Mobile Signal Quality',
                        unit: 'dBm',
                        graphType: 'line',
                        assetType: 'gateway'
                    },
                    component: DesktopDataPage
                },
                {
                    url: 'power_source',
                    title: 'Power Source',
                    dataType: {
                        type: 'GATEWAY_POWER_SOURCE',
                        category: 'GATEWAY_POWER_SOURCE',
                        label: 'Power Source',
                        unit: 'dB',
                        graphType: 'line',
                        assetType: 'gateway'
                    },
                    component: DesktopDataPage
                }
            ]);
        }
        let tabs = [
            {
                default: true,
                url: 'details',
                title: 'Details',
                component: GatewayOverviewPage
            },
            {
                url: 'data',
                hideChildren: true,
                title: 'Data',
                tabs: dataTabs
            },
            {
                url: 'devices',
                title: 'Devices',
                count: this.state.deviceCount,
                component: DesktopSiteAssetListPage
            },
            {
                url: 'settings',
                title: 'Settings',
                component: GatewaySettings
            },
            {
                url: 'events',
                title: 'Event Log',
                component: GatewaySettingsEventLog
            }
        ];
        if (this.state.account && this.state.account.god) {
            tabs.push({
                url: 'admin',
                title: 'Admin',
                tabs: [
                    {
                        url: 'ops',
                        title: 'Ops',
                        component: GatewayOps,
                        default: true
                    },
                    {
                        url: 'zigbee',
                        title: 'Zigbee Network',
                        component: GatewayZigbee,
                    },
                    {
                        url: 'firmware',
                        title: 'Firmware',
                        component: GatewayAdmin,
                    }
                ]
            })
        }
        return tabs;
    }

    loadTabs() {
        let type = this.getTypeAndId(this.props);
        if (type.item === 'site') {
            return this.loadSiteTabs();
        } else if (type.item === 'siteGroup') {
            return this.loadSiteGroupTabs();
        } else if (type.item === 'deviceGroup') {
            return this.loadDeviceGroupTabs();
        } else if (type.item === 'device') {
            return this.loadDeviceTabs();
        } else if (type.item === 'hub') {
            return this.loadHubTabs();
        } else if (type.item === 'client') {
            return this.loadClientTabs();
        }
    }

    render() {
        let _tabs = this.loadTabs();
        if (_tabs) {
            this.tabs = _tabs;
        }

        if (window._current_tab && window._current_tab.length && this.tabs) {
            let has_top_level_tab = this.tabs.find(t => t.url === window._current_tab[0]);
            if (has_top_level_tab) {
                // Check if tab has sub tab if relevant
                this.tabs.forEach(t => t.default = false);
                has_top_level_tab.default = true;
                if (window._current_tab.length > 1) {
                    if (has_top_level_tab.tabs) {
                        //  TODO Use recursive logic
                        let has_second_level_tab = has_top_level_tab.tabs.find(t => t.url === window._current_tab[1]);
                        if (has_second_level_tab) {
                            has_top_level_tab.tabs.forEach(t => t.default = false);
                            has_second_level_tab.default = true;
                        }
                    }
                }

            }
        } else {
            let _current_tab = [];
            if (this.tabs) {
                let default_tab = this.tabs.find(t => t.default);
                _current_tab.push(default_tab.url);
                if (_current_tab.tabs) {
                    //     TODO use recursive logic
                    let default_tab = this.tabs.find(t => t.default);
                    _current_tab.tabs.push(default_tab.url);
                }
                window._current_tab = _current_tab;
            }
        }

        let filterId = this.getTypeAndId(this.props).itemId;
        let item = this.getTypeAndId(this.props).item;
        let pageItems = [];

        if (this.state.user && this.state.item && this.tabs) {
            if (this.state.contextItems && this.state.contextItems.length) {
                pageItems.push(ReactDOM.createPortal(<ContextBar
                    contextItems={this.state.contextItems}/>, document.getElementById('contextBar')));
            }
            if (this.state.openClientSettings) {
                pageItems.push(<PartnerOrgDrawer
                    onClose={() => {
                        this.setState({openClientSettings: null});
                    }}
                    hasAdmin={this.state.orgPermission === 'admin'}
                    hasOwner={this.state.orgPermission === 'owner'}
                    open={!!this.state.openClientSettings}
                    item={this.state.openClientSettings}/>)
            }
            pageItems.push(<TabbedPage dataLoaded={this.state.item}
                                       className={this.state.pageClass}
                                       tabs={this.tabs}
                                       item={this.state.item}
                                       loading={this.loading}
                                       loaded={this.state.loaded}
                                       filterType={this.filterType}
                                       filterId={filterId}
                                       virtualGateway={this.state.virtualGateway}
                                       type={this.state.type}
                                       availableDataTypes={this.state.availableDataTypes}
                                       hubs={this.state.hubs}
                                       siteGroupString={item === 'siteGroup' || item === 'client' ? 'siteGroup' : null}
                                       listFilters={this.state.listFilters}
                                       editable={(this.state.orgPermission === 'edit' || this.state.orgPermission === 'admin' || this.state.orgPermission === 'owner')}
                                       site={this.state.site}
                                       dataItems={item === 'device' || item === 'hub' ? [this.state.item] : null}
                                       {...this.props}/>)
        } else {
            pageItems.push(<GenericLoader/>);
        }

        return pageItems;
    }
}

export default MainPage;
