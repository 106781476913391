import React from "react";
import {Breadcrumbs, Button, Toast} from "gd-react";
import BreadcrumbService from './../meta/breadcrumb-service';
import GridDuck from 'gridduck';
import history from "../meta/history";
import getFormat from "../services/formatter";
import {isMobile, isTablet} from "react-device-detect";
import IssuePopover from "./IssuePopover/IssuePopover";

class BreadcrumbList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {crumbs: []};
        let self = this;
        history.listen(() => {
            this.regenCrumbs();
        });
        GridDuck.getAccount({id: GridDuck.userId}).then(function (results) {
            self.setState({user: results});
            self.regenCrumbs();
        });
        this.getGatewayDataForCrumb = this.getGatewayDataForCrumb.bind(this);
        this.getSiteDataForCrumb = this.getSiteDataForCrumb.bind(this);
        this.getAssetDataForCrumb = this.getAssetDataForCrumb.bind(this);
        this.getAssetSiteDataForCrumb = this.getAssetSiteDataForCrumb.bind(this);
        this.getAuditSiteDataForCrumb = this.getAuditSiteDataForCrumb.bind(this);
        this.getSiteGroupDataForCrumb = this.getSiteGroupDataForCrumb.bind(this);
        this.getClientDataForCrumb = this.getClientDataForCrumb.bind(this);
    }

    getGatewayDataForCrumb(gatewayId, addOptions) {
        let self = this;
        return GridDuck.getGateway({id: gatewayId}).then(function (gateway) {
            let crumbDatum = {
                title: gateway.name,
                listener: gateway,
                icon: 'FaSquare',
                endAdornment: gateway.disconnected ? <IssuePopover type={'Gateway'} updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={gateway}/> : null,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                        endAdornment: gateway.disconnected ? <IssuePopover type={'Gateway'} updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={gateway}/> : null,
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/hub/" + gateway.id);
                }
            };
            if (addOptions && gateway._permission === 'admin') {
                crumbDatum.controlOptions = [
                    {
                        icon: {
                            color: 'gd-grey',
                            name: 'MdLock',
                            size: '14'
                        },
                        label: 'Share access',
                        onMenuItemClick: function () {
                            history.push('/site/' + gateway.siteId + '/settings/access')
                        }
                    },
                ]
            }
            return Promise.resolve(crumbDatum);
        });
    }

    getSiteDataForCrumb(siteId, addOptions, isLast, isMobile) {
        let self = this;
        return GridDuck.getSite({id: siteId}).then(function (site) {
            console.log('site in breadcrumb: ', site);
            let crumbDatum = {
                title: (!isLast && isMobile) ? '...' : site.name,
                listener: site,
                icon: 'FaBuilding',
                endAdornment: site.issues && !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={site}/> : null,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                        icon: 'FaBuilding',
                                        endAdornment: site.issues && !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={site}/> : null
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/site/" + site.id);
                }
            };
            if (addOptions && site._permission === 'admin') {
                crumbDatum.controlOptions = [
                    {
                        icon: {
                            color: 'gd-grey',
                            name: 'MdLock',
                            size: '14'
                        },
                        label: 'Share access',
                        onMenuItemClick: function () {
                            history.push('/site/' + site.id + '/settings/access')
                        }
                    },
                ]
            }
            return Promise.resolve(crumbDatum);
        });
    }

    getAssetDataForCrumb(assetId, addOptions) {
        let self = this;
        return GridDuck.getAsset({id: assetId}).then(function (asset) {
            let crumbDatum = {
                title: asset.name,
                icon: 'FaPlug',
                listener: asset,
                endAdornment: !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={asset}/> : null,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                        icon: 'FaPlug',
                                        endAdornment: !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={asset}/> : null
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/device/" + asset.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    }

    getAuditDataForCrumb(auditId, addOptions) {
        let self = this;
        return GridDuck.getAudit({id: auditId}).then(function (audit) {
            let crumbDatum = {
                title: 'Audit on ' + getFormat('lastContacted')(audit.createdDate),
                listener: audit,
                icon: 'FaClipboardCheck',
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                        icon: 'FaClipboardCheck'
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/audit/" + audit.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    }

    async getAssetSiteDataForCrumb(assetId, isLast, isMobile) {
        let self = this;
        let asset = await GridDuck.getAsset({id: assetId});
        let site = await GridDuck.getSite({id: asset.siteId});
        let crumbDatum = {
            title: (!isLast && isMobile) ? '...' : asset.siteName,
            icon: 'FaBuilding',
            endAdornment: site.issues && !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={site}/> : null,
            onBreadcrumbClick: function () {
                history.push("/site/" + asset.groupIds[0]);
            }
        };
        return Promise.resolve(crumbDatum);
    }

    async getAuditSiteDataForCrumb(auditId) {
        let self = this;
        let audit = await GridDuck.getAudit({id: auditId});
        let site = await GridDuck.getSite({id: audit.siteId});
        let crumbDatum = {
            title: audit.siteName,
            icon: 'FaBuilding',
            endAdornment: site.issues ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={site}/> : null,
            onBreadcrumbClick: function () {
                history.push("/site/" + audit.siteId);
            }
        };
        return Promise.resolve(crumbDatum);
    }

    getGatewaySiteDataForCrumb(gatewayId) {
        return GridDuck.getGateway({id: gatewayId}).then(function (gateway) {
            let crumbDatum = {
                title: gateway.siteName,
                icon: 'FaBuilding',
                onBreadcrumbClick: function () {
                    history.push("/site/" + gateway.siteId);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    }

    getOverviewData() {
        return Promise.resolve(
            {
                title: 'Everything',
                icon: 'FaGlobeEurope',
                onBreadcrumbClick: function () {
                    history.push("/siteGroup/all");
                }
            }
        );
    }

    getAdminData() {
        return Promise.resolve(
            {
                title: 'Admin Console',
                icon: 'FaLockOpen',
                onBreadcrumbClick: function () {
                    history.push("/admin-console");
                }
            }
        );
    }

    getAnomalyData() {
        return Promise.resolve(
            {
                title: 'AI Alerts',
                icon: 'FaRobot',
                onBreadcrumbClick: function () {
                    history.push("/anomaly-detection");
                }
            }
        );
    }

    getAlertsData() {
        return Promise.resolve(
            {
                title: isMobile ? 'Everything' : 'Issues',
                onBreadcrumbClick: function () {
                    history.push("/issues");
                }
            }
        );
    }

    getOrganisationData() {
        return Promise.resolve(
            {
                title: 'Organisation',
                icon: 'FaUserFriends',
                onBreadcrumbClick: function () {
                    history.push("/organisation");
                }
            }
        );
    }

    getProductsData() {
        return Promise.resolve(
            {
                title: 'Order Hardware',
                onBreadcrumbClick: function () {
                    history.push("/products");
                }
            }
        );
    }

    getDataData() {
        return Promise.resolve(
            {
                title: 'Data Downloads',
                icon: 'FaFileDownload',
                onBreadcrumbClick: function () {
                    history.push("/data-download");
                }
            }
        );
    }

    getAccountData() {
        return Promise.resolve(
            {
                title: 'Settings',
                icon: 'FaCog',
                onBreadcrumbClick: function () {
                    history.push("/settings");
                }
            }
        );
    }

    getDeveloperData() {
        return Promise.resolve(
            {
                title: 'Developer',
                icon: 'FaCode',
                onBreadcrumbClick: function () {
                    history.push("/developer");
                }
            }
        );
    }

    getRuleDataForCrumb(ruleId, addOptions) {
        let self = this;
        return GridDuck.getRule({id: ruleId}).then(function (rule) {
            let crumbDatum = {
                title: rule.name,
                listener: rule,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/rule/" + rule.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    };

    getReportDataForCrumb(reportId, addOptions) {
        let self = this;
        return GridDuck.getRule({id: reportId}).then(function (report) {
            let crumbDatum = {
                title: report.name,
                listener: report,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/report/" + report.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    };

    getGroupDataForCrumb(groupId, addOptions) {
        let self = this;
        return GridDuck.getGroup({id: groupId}).then(function (group) {
            let crumbDatum = {
                title: group.name,
                listener: group,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/device-groups/" + group.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    };

    getSiteGroupDataForCrumb(siteGroupId, addOptions, isLast, isMobile) {
        let self = this;

        return GridDuck.getSiteGroup({id: siteGroupId}).then(function (siteGroup) {
            let crumbDatum = {
                title: (!isLast && isMobile) ? '...' : (siteGroup.name === 'All' && !siteGroup.siteIds.length ? 'Getting Started' : siteGroup.name === 'All' ? 'Everything' : siteGroup.name),
                listener: siteGroup,
                icon: siteGroup.name === 'All' ? 'FaBorderAll' : 'FaGlobeEurope',
                endAdornment: siteGroup.issues && !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={siteGroup}/> : null,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                        endAdornment: siteGroup.issues && !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} itemId={siteGroup.id}/> : null
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/siteGroup/" + siteGroup.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    };

    getClientDataForCrumb(clientId, addOptions, isLast, isMobile) {
        let self = this;

        return GridDuck.getOrgPartner({id: clientId}).then(function (client) {
            let crumbDatum = {
                title: (!isLast && isMobile) ? '...' : client.name,
                listener: client,
                icon: 'FaBriefcase',
                endAdornment: client.issues && !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={client}/> : null,
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                        endAdornment: client.issues && !isMobile ? <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} itemId={client.id}/> : null
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/client/" + client.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    };

    getDeviceGroupDataForCrumb(deviceGroupId, addOptions, isLast, isMobile) {
        let self = this;

        return GridDuck.getGroup({id: deviceGroupId}).then(function (deviceGroup) {
            let crumbDatum = {
                title: deviceGroup.name,
                listener: deviceGroup,
                icon: 'FaCubes',
                updateFn: function (index) {
                    return function (field, val) {
                        if (field === 'name') {
                            self.setState(({crumbs}) => ({
                                crumbs: [
                                    ...crumbs.slice(0, index),
                                    {
                                        ...crumbs[index],
                                        title: val,
                                        icon: 'FaCubes'
                                    },
                                    ...crumbs.slice(index + 1)
                                ]
                            }));
                        }
                    }
                },
                onBreadcrumbClick: function () {
                    history.push("/deviceGroup/" + deviceGroup.id);
                }
            };
            return Promise.resolve(crumbDatum);
        });
    };

    getDataForCrumb(crumb, isLast) {
        let promise;

        switch (crumb.type) {
            case 'admin-console':
                promise = this.getAdminData();
                break;
            case 'anomaly-detection':
                promise = this.getAnomalyData();
                break;
            case 'issues':
                promise = this.getAlertsData();
                break;
            case 'organisation':
                promise = this.getOrganisationData();
                break;
            case 'order-hardware':
                promise = this.getProductsData();
                break;
            case 'data':
                promise = this.getDataData();
                break;
            case 'settings':
                promise = this.getAccountData();
                break;
            case 'developer':
                promise = this.getDeveloperData();
                break;
            case 'automations':
                promise = Promise.resolve(
                    {
                        title: 'Automations',
                        icon: 'FaRobot',
                        onBreadcrumbClick: function () {
                            history.push("/automations");
                        }
                    }
                );
                break;
            case 'alerts':
                promise = Promise.resolve(
                    {
                        title: 'Alerts',
                        icon: 'FaBell',
                        onBreadcrumbClick: function () {
                            history.push("/alerts");
                        }
                    }
                );
                break;
            case 'tariffs':
                promise = Promise.resolve(
                    {
                        title: 'Tariffs',
                        onBreadcrumbClick: function () {
                            history.push("/tariffs");
                        }
                    }
                );
                break;
            case 'invoices':
                promise = Promise.resolve(
                    {
                        title: 'Invoices',
                        onBreadcrumbClick: function () {
                            history.push("/invoices");
                        }
                    }
                );
                break;
            case 'reports':
                promise = Promise.resolve(
                    {
                        title: 'Reports',
                        icon: 'FaChartArea',
                        onBreadcrumbClick: function () {
                            history.push("/reports");
                        }
                    }
                );
                break;
            case 'locations':
                promise = Promise.resolve(
                    {
                        title: 'Locations',
                        onBreadcrumbClick: function () {
                            history.push("/settings/organisation/locations");
                        }
                    }
                );
                break;
            case 'custom-fields':
                promise = Promise.resolve(
                    {
                        title: 'Custom Fields',
                        onBreadcrumbClick: function () {
                            history.push("/custom-fields");
                        }
                    }
                );
                break;

            case 'tags':
                promise = Promise.resolve(
                    {
                        title: 'Groups / Tags',
                        onBreadcrumbClick: function () {
                            history.push("/groups/tags");
                        }
                    }
                );
                break;
            case 'siteGroupId':
                promise = this.getSiteGroupDataForCrumb(crumb.id, isLast && !this.props.isMobile, isLast, this.props.isMobile);
                break;
            case 'clientId':
                promise = this.getClientDataForCrumb(crumb.id, isLast && !this.props.isMobile, isLast, this.props.isMobile);
                break;
            case 'deviceGroupId':
                promise = this.getDeviceGroupDataForCrumb(crumb.id, isLast && !this.props.isMobile, isLast, this.props.isMobile);
                break;
            case 'siteId':
                promise = this.getSiteDataForCrumb(crumb.id, isLast && !this.props.isMobile, isLast, this.props.isMobile);
                break;
            case 'hubId':
                promise = this.getGatewayDataForCrumb(crumb.id, isLast && !this.props.isMobile);
                break;
            case 'deviceId':
                promise = this.getAssetDataForCrumb(crumb.id, isLast && !this.props.isMobile);
                break;
            case 'auditId':
                promise = this.getAuditDataForCrumb(crumb.id, isLast);
                break;
            case 'assetSiteId':
                promise = this.getAssetSiteDataForCrumb(crumb.id, isLast, this.props.isMobile);
                break;
            case 'auditSiteId':
                promise = this.getAuditSiteDataForCrumb(crumb.id, isLast);
                break;
            case 'siteGatewayId':
                promise = this.getGatewaySiteDataForCrumb(crumb.id, isLast && !this.props.isMobile);
                break;
            case 'ruleId':
                promise = this.getRuleDataForCrumb(crumb.id, isLast && !this.props.isMobile);
                break;
            case 'reportId':
                promise = this.getReportDataForCrumb(crumb.id, isLast && !this.props.isMobile);
                break;
            case 'create':
                promise = Promise.resolve(
                    {
                        title: 'Create',
                    }
                );
                break;
            default:
                promise = Promise.resolve(null);
                break;
        }
        return promise;
    }

    componentWillUnmount() {
        this.state.crumbs.forEach(function (crumb, index) {
            if (crumb.listener) {
                crumb.listener.off('updated', crumb.updateFn(index));
            }
        });
    }

    regenCrumbs() {
        if (!this.state.user) {
            return;
        }
        this.state.crumbs.forEach(function (crumb, index) {
            if (crumb.listener) {
                crumb.listener.off('updated', crumb.updateFn(index));
            }
        });
        let self = this;
        this.locations = {};
        let matches = history.location.pathname.split('/');
        matches.forEach(function (match, index) {
            if (index % 2 === 1 && matches.length > index + 1) {
                self.locations[match + 'Id'] = matches[index + 1];
            }
        })
        if (!this.locations) {
            return;
        }
        this.state.crumbs.forEach(function (crumb, index) {
            if (crumb.listener) {
                crumb.listener.off('updated', crumb.updateFn(index));
            }
        });
        let promises = [];
        Object.keys(this.locations).forEach(function (parameterName, index) {
            if (parameterName === 'siteId') {
                promises.unshift(
                    self.getDataForCrumb({
                            id: BreadcrumbService.siteGroup || 'all_' + self.state.user.id + '_' + self.state.user.organisationId,
                            type: 'siteGroupId'
                        },
                        false)
                );
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'allSites'
                    })
                );
            }
            if (parameterName === 'clientId') {
                promises.unshift(
                    self.getDataForCrumb({
                            id: BreadcrumbService.siteGroup || 'all_' + self.state.user.id + '_' + self.state.user.organisationId,
                            type: 'client'
                        },
                        true)
                );
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'client'
                    })
                );
            }
            if (parameterName === 'hubId') {
                promises.unshift(
                    self.getDataForCrumb({
                            id: BreadcrumbService.siteGroup || 'all_' + self.state.user.id + '_' + self.state.user.organisationId,
                            type: 'siteGroupId'
                        },
                        false)
                );
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'allSites'
                    })
                );
            }
            if (parameterName === 'deviceId') {
                promises.unshift(
                    self.getDataForCrumb({
                            id: BreadcrumbService.siteGroup || 'all_' + self.state.user.id + '_' + self.state.user.organisationId,
                            type: 'siteGroupId'
                        },
                        false)
                );
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'allSites'
                    })
                );
            }
            if (parameterName === 'auditId') {
                promises.unshift(
                    self.getDataForCrumb({
                            id: BreadcrumbService.siteGroup || 'all_' + self.state.user.id + '_' + self.state.user.organisationId,
                            type: 'siteGroupId'
                        },
                        false)
                );
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'allSites'
                    })
                );
            }

            if (parameterName === 'automationId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'automations'
                    })
                );
            }
            if (parameterName === 'alertId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'alerts'
                    })
                );
            }
            if (parameterName === 'customFieldId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'customFields'
                    })
                );
            }
            if (parameterName === 'tariffId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'tariffs'
                    })
                );
            }
            if (parameterName === 'invoiceId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'invoices'
                    })
                );
            }
            if (parameterName === 'locationId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'locations'
                    })
                );
            }
            if (parameterName === 'reportId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'reports'
                    })
                );
            }
            if (parameterName === 'siteGroupId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'allSites'
                    })
                );
            }
            if (parameterName === 'clientId') {
                promises.unshift(
                    self.getDataForCrumb({
                        type: 'client'
                    })
                );
            }
            if (parameterName === 'hubId') {
                // promises.push(
                //     self.getDataForCrumb({
                //         id: self.locations[parameterName],
                //         type: 'siteGatewayId'
                //     })
                // );
            }
            if (parameterName === 'deviceId') {
                promises.push(
                    self.getDataForCrumb({
                        id: self.locations[parameterName],
                        type: 'assetSiteId'
                    })
                );
            }
            if (parameterName === 'auditId') {
                promises.push(
                    self.getDataForCrumb({
                        id: self.locations[parameterName],
                        type: 'auditSiteId'
                    })
                );
            }
            promises.push(
                self.getDataForCrumb({
                        id: self.locations[parameterName],
                        type: parameterName
                    },
                    index + 1 === Object.keys(self.locations).filter(function (p) {
                        return !(~p.indexOf('subpage'));
                    }).length || index + 2 === Object.keys(self.locations).filter(function (p) {
                        return !(~p.indexOf('subpage'));
                    }).length)
            );

        });
        if (history.location.pathname.substr(1, 13) === 'admin-console') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'admin-console'
                })
            );
        }
        if (history.location.pathname.substr(1, 17) === 'anomaly-detection') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'anomaly-detection'
                })
            );
        }
        if (history.location.pathname.substr(1, 6) === 'issues') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'issues'
                })
            );
        }
        if (history.location.pathname.indexOf('organisation') > -1) {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'organisation'
                })
            );
        }
        if (history.location.pathname.indexOf('order-hardware') > -1) {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'order-hardware'
                })
            );
        }
        if (history.location.pathname.substr(1, 4) === 'data') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'data'
                })
            );
        }
        if (history.location.pathname.substr(1, 8) === 'settings') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'settings'
                })
            );
        }
        if (history.location.pathname.indexOf('developer') > -1) {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'developer'
                })
            );
        }
        if (history.location.pathname.substr(1, 11) === 'automations') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'automations'
                })
            );
        }
        if (history.location.pathname.substr(1, 6) === 'alerts') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'alerts'
                })
            );
        }
        if (history.location.pathname.substr(1, 7) === 'tariffs' && history.location.pathname.indexOf('create') === -1) {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'tariffs'
                })
            );
        }
        if (history.location.pathname.substr(1, 8) === 'invoices' && history.location.pathname.indexOf('create') === -1) {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'invoices'
                })
            );
        }
        if (history.location.pathname.substr(1, 7) === 'reports' && history.location.pathname.indexOf('create') === -1) {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'reports'
                })
            );
        }

        if (history.location.pathname.substr(1, 9) === 'locations') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'locations'
                })
            );
        }
        if (history.location.pathname.substr(1, 13) === 'custom-fields') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'custom-fields'
                })
            );
        }
        if (history.location.pathname === '/groups/custom') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'groups'
                })
            );
        }
        if (history.location.pathname === '/groups/tags') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'tags'
                })
            );
        }

        if (history.location.pathname.substr(0, 15) === '/reports/create') {
            promises.unshift(
                self.getDataForCrumb({
                    type: 'create'
                })
            )
            promises.unshift(
                self.getDataForCrumb({
                    type: 'reports'
                })
            );
        }

        Promise.all(promises).then(function (crumbData) {
            let crumbs = crumbData.filter(d => d !== null);

            self.setState({crumbs: crumbs});
            crumbs.forEach(function (crumb, index) {
                if (crumb.listener) {
                    crumb.listener.on('updated', crumb.updateFn(index))
                }
            });
        }).catch(function (err) {
            console.log("Crumb error", err);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match !== prevProps.match) {
            this.regenCrumbs();
        }
    }

    render() {
        return (<div style={{
            display: 'flex',
            flex: 'auto',
            overflow: 'hidden',
            position: 'relative'
        }}>

            <Toast onClose={() => this.setState({showToast: null})} message={this.state.showToast}
                   open={this.state.showToast}
                   severity="success"
                   anchorOrigin={{
                       vertical: 'bottom',
                       horizontal: 'left',
                   }}/>
            {this.state.user && <Breadcrumbs crumbData={this.state.crumbs}/>}
        </div>)
    }

}

export default BreadcrumbList;