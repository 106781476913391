import React from 'react';
import './PortfolioList.scss';
import history from "../../meta/history";
import {Loader, Icon, Tooltip} from "gd-react";
import IssuePopover from "../../components/IssuePopover/IssuePopover";

class PortfolioListItem extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let padding = this.props.open ? 10 : 0;
        return [(<div
            className={'menu-item portfolio ' + (this.props.url ? history.location.pathname.substring(1, this.props.url.length + 1) === this.props.url ? 'selected' : '' : '')}
            style={{
                paddingLeft: ((this.props.indent ? this.props.indent : 0) * padding) + 'px',
                paddingRight: ((this.props.indent ? this.props.indent : 0) * padding) + 'px'
            }}
            onClick={this.props.onClick}>
            <div className={'left-nav-icon'} style={{
                left: ((this.props.indent ? this.props.indent : 0) * padding) + 'px'
            }}>
                {!this.props.loader ? !this.props.open ?
                    <Tooltip label={this.props.name}
                             position={'right'}>
                        {/*{this.props.activeIssues ? <span*/}
                        {/*        className={'alert-number'}>{this.props.icon !== 'FaPlug' ? this.props.activeIssues : <Icon size={'6'} icon={'FaExclamation'}/>}</span> :*/}
                        {/*    <Icon icon={this.props.icon} size={'13'}/>}*/}
                        <Icon icon={this.props.icon} size={'13'}/>
                    </Tooltip> : <Icon icon={this.props.icon} size={'13'}/> : <Loader type={'circular'} size={13}/>}
            </div>


            <p style={{
                maxWidth: (this.props.indent ? 160 : 140) + 'px'
            }}>{!this.props.loader ? this.props.name : 'Loading ' + this.props.loader + '...'}</p>
            <span className={'flex-spacer'}/>
            {!this.props.loader && <IssuePopover updateDisconnectedDrawer={this.props.updateDisconnectedDrawer} item={this.props.item}/>}

             <div
                onClick={this.props.onExpand && !this.props.noSites ? (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!this.props.loader && !this.props.loading) {
                        if (this.props.expanded) {
                            this.props.onMinimise(e)
                        } else {
                            this.props.onExpand(e)
                        }
                    }
                } : null}
                className={'left-nav-icon right'}>
                 {this.props.onExpand && !this.props.noSites ? <Icon size={'11'} icon={this.props.expanded ? 'FaChevronDown' : 'FaChevronRight'}/> : null}
            </div>
        </div>), (this.props.subItems), (this.props.expanded && !this.props.subItems && this.props.hasSubItems ? <div
            className={'menu-item portfolio'}
            style={{
                paddingLeft: ((this.props.indent ? this.props.indent : 0) * padding) + 'px'
            }}>  <div className={'left-nav-icon'} style={{
            left: ((this.props.indent ? this.props.indent : 0) * padding) + 'px'
            }}><Loader type={'circular'} size={13}/></div><p style={{
            maxWidth: (this.props.indent ? 160 : 140) + 'px'
        }}>{'Loading devices...'}</p></div>
            : null)]}}
            export default PortfolioListItem;