import React from "react";
import {
    Button,
    Loader,
    Card,
    CardBody,
    CardHeader,
    Dropdown,
    GdAutocomplete,
    Input,
    StateOptions,
    Toast
} from "gd-react";
import SaveBar from "../../components/SaveBar/SaveBar";
import DeleteModal from "../../modals/Delete/DeleteModal";
import GridDuck from 'gridduck'
import Toggle from "../../components/Toggle/Toggle";
import history from "../../meta/history";
import AddEditDeviceCategoryModal from "../../modals/AddEditDeviceCategory/AddEditDeviceCategoryModal";
import GenericLoader from "../../components/GenericLoader";
import './AssetPage.scss'

class AssetSettingsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.setAssetDetails = this.setAssetDetails.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.getApplianceTypes = this.getApplianceTypes.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.loadTypes = [
            {
                id: 0,
                title: 'Consumption Only',
                value: 'CONSUMPTION'
            },
            {
                id: 1,
                title: 'Generation Only',
                value: 'GENERATION'
            },
            {
                id: 2,
                title: 'Consumption and Export',
                value: 'EXPORT'
            },
        ]
        this.errors = {};
        this.state = {
            loaded: false,
            modbusModels: [],
            asset: {
                name: this.props.item.name,
                impKwh: this.props.item.dataType === 'DELIVERED_GAS' ? 1 / this.props.item.impKwh : this.props.item.impKwh,
                dataType: this.props.item.dataType,
                locked: this.props.item.locked,
                gatewayId: this.props.item.gatewayId,
                gatewayName: this.props.item.gatewayName,
                siteName: this.props.item.siteName,
                modbusType: this.props.item.modbusId,
                deviceCategory: this.props.item.deviceCategory,
                loadType: this.loadTypes.find((t) => t.id === this.props.item.loadType)?.value || this.loadTypes[0].value
            },
            applianceType: this.props.item.applianceType,
            generation: this.props.item.generation,
            applianceTypes: [],
            deviceCategories: [],
            showDeleteModal: false,
            showDeviceCategoryModal: false,
            siteName: this.props.item.siteName,
            gatewayName: this.props.item.gatewayName,
        }
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (account) {
                self.setState({account: account});
            });
        this.asset = this.props.item;
        this.getGateways = this.getGateways.bind(this);
        this.getSites = this.getSites.bind(this);
        this.selectGateway = this.selectGateway.bind(this);
        this.selectSite = this.selectSite.bind(this);
        this.syncDeviceCategory = this.syncDeviceCategory.bind(this)

    }

    selectSite(s, sObj) {
        if (!s || !sObj) return;
        let asset = this.state.asset;
        asset.groupIds = [sObj.id];
        asset.siteId = sObj.id;
        asset.siteName = sObj.title;
        this.setState({asset: asset, hasChanged: true});
    }

    selectGateway(gw, gwObj) {
        if (!gw || !gwObj) return;
        let asset = this.state.asset;
        asset.gatewayId = gwObj.id;
        asset.gatewayName = gwObj.title;
        this.setState({asset: asset, hasChanged: true});
    }

    syncDeviceCategory() {
        let deviceCategory = this.props.item.deviceCategory;
        let existingCategory = this.state.deviceCategories.find((c) => c.title === deviceCategory)
        if (existingCategory) {
            let asset = this.state.asset;
            asset.deviceCategory = deviceCategory
            this.setState({asset: asset})
        }
    }

    async getGateways(searchTerm) {
        if (searchTerm && searchTerm.length) {
            let site_gws = await GridDuck.getGateways({
                filters: [{
                    field: 'search',
                    value: searchTerm
                }]
            });
            return site_gws.list
                .filter(s => s._permission === 'admin')
                .map(
                    s => ({
                        id: s.id,
                        title: s.name
                    })
                )
        } else return []
    }

    async getSites(searchTerm, reset, pageNo) {
        if (!pageNo) {
            pageNo = 0;
        }
        if (searchTerm && searchTerm.length) {
            let sites = await GridDuck.getSites({
                offset: 25 * pageNo,
                filters: [{
                    field: 'search',
                    value: searchTerm
                }]
            });

            return sites.list
                .filter(s => s._permission === 'admin')
                .map(
                    s => ({
                        id: s.id,
                        title: s.name
                    })
                )
        } else return []
    }

    handleClose() {
        this.setState({open: false})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item && this.props.item && prevProps.item.id !== this.props.item.id) {
            this.setAssetDetails();
            this.getDeviceCategory().then((res) => this.setState({deviceCategories: res}))
        }
    }

    async componentDidMount() {
        let promises = [
            this.getApplianceTypes(),
            this.getDeviceCategory(),
            this.getModbusModels()
        ]
        let res = await Promise.all(promises)
        this.state.asset['modbusType'] = res[2].find((model) => model.id === this.props.item.modbusId)?.title;
        this.setState({
            applianceTypes: res[0],
            deviceCategories: res[1],
            modbusModels: res[2],
            asset: this.state.asset,
            loaded: true
        })
    }

    async getModbusModels() {
        let res = await GridDuck.getModbusModels({filters: []})
        let list = res.list.map(
            s => ({
                id: s.id,
                title: s.displayName,
            })
        )
        return Promise.resolve(list)
    }

    async getDeviceCategory() {
        let filters = [{
            field: 'assetIds',
            value: [this.props.item.id]
        }]

        let res = await GridDuck.getDeviceCategorys({
            filters: filters
            // filters: []
        })
        let list = res.list.map(
            s => ({
                id: s.id,
                title: s.name,
                color: s.colour,
                icon: s.icon
            })
        )
        let addNewElement =
            {
                id: 'add_new',
                value: 'add_new',
                title: 'Create New',
                color: '#49b9c4',
                icon: 'FaPlus'
            }
        list.unshift(addNewElement)
        return Promise.resolve(list)
    }

    checkErrors() {
        let errors = {};
        if (!this.state.asset.name.length) errors['name'] = true;
        if (this.props.item.sku === 'MET-REA' && (!this.state.asset.impKwh)) errors['impKwh'] = true;
        this.errors = errors;
    }

    setAssetDetails() {
        let prevApplianceType;
        prevApplianceType = this.state.applianceTypes.find((t) => t.title === this.props.item.applianceType)
        if (!prevApplianceType) prevApplianceType = this.state.applianceTypes.find((t) => t.id === this.props.item.applianceType)
        this.setState({
            asset: {
                name: this.props.item.name,
                impKwh: this.props.item.dataType === 'DELIVERED_GAS' ? 1 / this.props.item.impKwh : this.props.item.impKwh,
                gatewayId: this.props.item.gatewayId,
                gatewayName: this.props.item.gatewayName,
                siteName: this.props.item.siteName,
                siteId: this.props.item.siteId,
                dataType: this.props.item.dataType,
                locked: this.props.item.locked,
                modbusType: this.state.modbusModels.find((model) => model.id === this.props.item.modbusId)?.title,
                loadType: this.loadTypes.find((t) => t.id === this.props.item.loadType)?.value || this.loadTypes[0].value,
                deviceCategory: this.props.item.deviceCategory
            },
            applianceType: this.props.item.applianceType,
            generation: this.props.item.generation,
            hasChanged: false
        });
        if (prevApplianceType) {
            this.setState({applianceType: prevApplianceType.title})
        } else {
            this.setState({applianceType: null});
        }
    }

    getApplianceTypes() {
        return GridDuck.getApplianceTypes({
            sortBy: {
                field: 'name',
                type: 'asc'
            },
            filters: [
                {
                    field: 'search',
                    value: ''
                }
            ]
        }).then((catList) => {
            return Promise.resolve(
                catList.list
                    .map(
                        c => ({
                            id: c.id,
                            title: c.name
                        })
                    )
            )
        })
    }

    saveChanges(e) {
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) {
            return reject();
        }).catch(function (resolve, reject) {
            return reject();
        });
        if (e) {
            e.preventDefault();
        }
        let self = this;
        let applianceType = this.state.applianceType;
        let generation = this.state.generation;
        // applianceType = this.state.applianceTypes.find((t) => t.title === this.state.applianceType)
        let obj = {
            name: this.state.asset.name,
            impKwh: this.state.asset.dataType === 'DELIVERED_GAS' ? 1 / this.state.asset.impKwh : this.state.asset.impKwh,
            locked: this.state.asset.locked,
            generation: this.state.generation,
            loadType: this.loadTypes.find((l) => l.value === this.state.asset.loadType).id,
            deviceCategory: this.state.asset.deviceCategory,
            modbusId: this.state.modbusModels.find((model) => model.title === this.state.asset.modbusType)?.id || null
        }
        if (this.state.asset.groupIds && this.state.asset.groupIds !== this.props.item.groupIds) {
            obj.groupIds = this.state.asset.groupIds;
        }
        if (this.props.item.gatewayId !== this.state.asset.gatewayId) {
            obj.gatewayId = this.state.asset.gatewayId;
        }
        if (applianceType) {
            obj.applianceType = applianceType;
        }
        if (this.state.asset.dataType) {
            obj.dataType = this.state.asset.dataType;
        }
        return this.props.item.set(obj).then(function () {
            self.setState({open: true, hasChanged: false})
            self.setAssetDetails();
        });
    }

    cancelChanges() {
        this.setAssetDetails();
    }

    async onFormChange(val, option) {
        if (val.target.name === 'deviceCategory' && val.target.value === 'add_new') {
            val.stopPropagation();
            val.preventDefault();
            return this.setState({showDeviceCategoryModal: true})
        }

        if (val.target.name === 'applianceType') {
            this.state.applianceType = val.target.value
        } else {
            this.state.asset[val.target.name] = val.target.value;
        }
        this.setState({asset: this.state.asset, applianceType: this.state.applianceType, hasChanged: true});
    }

    showDeleteModal() {
        this.setState({showDeleteModal: true})
    }

    hasChanged() {
        let self = this;
        let hasChanged = false;
        // let applianceType, applianceTypeName;
        // applianceType = this.state.applianceTypes ? this.state.applianceTypes.find((t) => t && t.title === this.state.applianceType) : null;
        // if (applianceType) {
        //     applianceTypeName = applianceType.title
        //     if (applianceTypeName !== self.asset.applianceType) {
        //         hasChanged = true;
        //     }
        // }

        if (self.props.item && self.state.asset && this.props.loaded) {
            if (self.state.applianceType !== self.props.item.applianceType) {
                let applianceType = this.state.applianceTypes ? this.state.applianceTypes.find((t) => t && t.id === self.props.item.applianceType || t.title === self.props.item.applianceType) : null;
                if ((applianceType && applianceType.id !== self.props.item.applianceType) || (!applianceType && self.state.applianceType)) {
                    hasChanged = true;
                }
            }
            if (self.state.generation !== self.props.item.generation) {
                hasChanged = true
            }
            Object.entries(self.props.item).map(([key, value]) => {
                let updatedKey = key.replace(/^_+/i, '');
                if ((updatedKey in self.state.asset) && self.state.asset[updatedKey] !== value) {
                    hasChanged = true;
                }
            })
        }
        return hasChanged;
    }

    render() {
        let self = this;
        let assetType = this.props.item && this.props.item.sku ? StateOptions.find(o => o.type === this.props.item.sku) || StateOptions.find(o => o.type === 'default') : StateOptions.find(o => o.type === 'default');
        this.checkErrors();
        let footerSaveBar = this.state.hasChanged ?
            <div className={'detail-content'}>
                <SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/></div> : '';

        let generalCard;
        let advancedCard;
        let impKwhBr;
        let impKwhField;
        let generationField;
        if (this.props.item && this.props.item.sku && (this.props.item.sku === 'MET-REA' || this.props.item.sku.substr(0, 9) === 'BLE_PULSE' || this.props.item.sku.substr(0, 12) === 'NB-IOT-PULSE')) {
            let unit = 'Impressions per kWh';
            if (this.state.asset.dataType === 'DELIVERED_WATER') {
                unit = 'Impressions per m3'
            }
            if (this.state.asset.dataType === 'DELIVERED_GAS') {
                unit = 'm3 per impression'
            }
            if (this.state.asset.dataType === 'DELIVERED_WATER_HOT') {
                unit = 'Impressions per m3'
            }
            impKwhBr = (<br/>);
            impKwhField = (<Input error={this.errors['impKwh'] && this.state.triedToSave} onKeyPress={(event) => {
                if (event.key === 'Enter') this.saveChanges();
            }} label={unit} required name={'impKwh'}
                                  value={this.state.asset.impKwh}
                                  disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                                  onChange={this.onFormChange}/>);
        }
        let applianceTypeField;
        let dataTypeField;
        let applianceLockOption;
        if (this.props.item.sku && assetType.options.find(o => o.state === 'ON') !== undefined) {
            applianceLockOption = (
                <div className={'settings-row'}>
                    <Toggle
                        disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                        onClick={function () {
                            self.state.asset.locked = !self.state.asset.locked;
                            self.setState({asset: self.state.asset})
                        }}
                        name={'locked'}
                        inactivestatename={'Monitoring Only'}
                        activestatename={'Monitoring Only'}
                        active={this.state.asset.locked ? 'true' : 'false'}
                    />
                </div>
            )
        }
        let loadTypeField;
        if (this.props.item && this.props.item.sku && (this.props.item.sku === 'SEN-POW-OWON-OLD' || this.props.item.sku === 'SEN-POW-OWON')) {
            loadTypeField = <div className={'settings-row'}>
                <Dropdown
                    name={'loadType'}
                    value={this.state.asset.loadType}
                    fixeditems={this.loadTypes}
                    disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                    placeholder={'Select Load Type'}
                    label="Load Type"
                    onChange={(var1, var2) => this.onFormChange(var1, var2)}
                />
            </div>
        }
        let modbusTypeField;
        if (this.props.item && this.props.item.sku && (this.props.item.sku === 'MODBUS')) {
            modbusTypeField = <div className={'settings-row'}>
                <Dropdown
                    name={'modbusType'}
                    value={this.state.asset.modbusType}
                    fixeditems={this.state.modbusModels}
                    disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                    placeholder={'Select Modbus Type'}
                    label="Modbus Type"
                    onChange={(var1, var2) => this.onFormChange(var1, var2)}
                />
            </div>
        }
        // if (this.state.account && this.state.account.god) {
        //     generationField = (<div className={'settings-row'}>
        //         <Toggle
        //             onClick={function () {
        //                 self.state.generation = !self.state.generation;
        //                 self.setState({asset: self.state.asset, hasChanged: true})
        //             }}
        //             name={'generation'}
        //             inactivestatename={'Reverse Direction'}
        //             activestatename={'Reverse Direction'}
        //             disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
        //             active={this.state.generation ? 'true' : 'false'}
        //         />
        //     </div>)
        // }

        if (this.props && this.props.item && this.props.item.sku && this.props.item.sku.substr(0, 12) !== 'NB-IOT-PULSE' && this.props.item.sku.substr(0, 9) !== 'BLE_PULSE' && this.props.item.sku !== 'SEN-TEM-HUM' && this.props.item.sku !== 'SEN-LIGHT-MOTION') {
            applianceTypeField = (<div className={'settings-row'}>
                <Dropdown
                    name={'applianceType'}
                    value={this.state.applianceType}
                    fixeditems={this.state.applianceTypes}
                    disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                    placeholder={'Device Label'}
                    label="Device Label"
                    onChange={(var1, var2) => this.onFormChange(var1, var2)}
                />
            </div>)

        } else if (this.props && this.props.item && this.props.item.sku && this.props.item.sku !== 'SEN-TEM-HUM' && this.props.item.sku !== 'SEN-LIGHT-MOTION') {
            dataTypeField = (
                <div className={'settings-row'}>
                    <Dropdown
                        name={'dataType'}
                        value={this.state.asset.dataType}
                        fixeditems={[
                            {value: 'DELIVERED', title: 'Electricity'},
                            {value: 'DELIVERED_GAS', title: 'Gas'},
                            {value: 'DELIVERED_WATER', title: 'Water'},
                            {value: 'DELIVERED_WATER_HOT', title: 'Hot water'}
                        ]}
                        disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                        placeholder={'Meter Type'}
                        label="Meter Type"
                        onChange={(var1, var2) => this.onFormChange(var1, var2)}
                    />
                </div>
            )
        }

        let deviceCategoryField;

        if (this.state.showDeviceCategoryModal || !this.state.deviceCategories.length) {
            deviceCategoryField = (
                <div className={'settings-row'}>
                    <Loader type={'circular'} size={24}/>
                </div>
            )
        } else {
            deviceCategoryField = (
                <div className={'settings-row'}>
                    <Dropdown
                        name={'deviceCategory'}
                        value={this.state.asset.deviceCategory}
                        fixeditems={this.state.deviceCategories}
                        disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                        placeholder={'Device Category'}
                        label="Device Category"
                        onChange={(var1, var2) => this.onFormChange(var1, var2)}
                    />
                </div>
            )
        }

        if (this.props.item) {
            generalCard = (
                <Card className={'asset-general-settings'}>
                    <CardHeader title={'General'}/>
                    <CardBody>
                        <Input error={this.errors['name'] && this.state.triedToSave} onKeyPress={(event) => {
                            if (event.key === 'Enter') this.saveChanges();
                        }} top='true' label={'Name'} required name={'name'} value={this.state.asset.name}
                               onChange={this.onFormChange}
                               disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                        />
                        {applianceTypeField}
                        {deviceCategoryField}
                        {applianceLockOption}
                        {dataTypeField}
                        {impKwhField}
                        {impKwhBr}
                        {loadTypeField}
                        {modbusTypeField}
                        {generationField}
                    </CardBody>
                </Card>
            )
            advancedCard = (
                <Card>
                    <CardHeader title={'Advanced'}/>
                    <CardBody>
                        {this.props.item.sku !== 'VIRTUAL-PARENT' ?
                            <GdAutocomplete async name={'gatewayId'} getOptionSelected={function (option, value) {
                                if (option && value) return option.id === value.id;
                            }}
                                            value={this.state.asset.gatewayName}
                                            placeholder={'Search Gateways'}
                                            getList={self.getGateways}
                                            onChange={this.selectGateway} label="Move Hub"/> : ''}
                        <br/>
                        {this.props.item.sku !== 'VIRTUAL-PARENT' ?
                            <GdAutocomplete async name={'groupIds'} getOptionSelected={function (option, value) {
                                if (option && value) return option.id === value.id;
                            }}
                                            value={this.state.asset.siteName}
                                            placeholder={'Search Sites'}
                                            getList={self.getSites}
                                            onChange={this.selectSite} label="Move Site"/> : ''}
                        <br/>
                        {this.props.item._permission === 'admin' ?
                            <Button label={'Delete Device'} color={'gd-red'} onClick={this.showDeleteModal}/> : ''}
                    </CardBody>
                </Card>
            )
        }

        return (
            <div className={"page grey narrow not-flex"}>
                {this.state.loaded ? <div className={'column top'}>
                    <div className={'detail-content'}>
                        {generalCard}

                    </div>
                    <div className={'detail-content'}>
                        {this.props.item._permission === 'admin' ? advancedCard : null}
                    </div>
                    {footerSaveBar}
                </div> : <GenericLoader/>}
                {this.state.showDeviceCategoryModal ? <AddEditDeviceCategoryModal
                    onClose={() => {
                        this.getDeviceCategory().then((res) => {
                            this.setState({deviceCategories: res, showDeviceCategoryModal: null})
                            // this.syncDeviceCategory()
                        })
                    }}
                    open={!!this.state.showDeviceCategoryModal}
                    item={this.state.showDeviceCategoryModal}
                    setNewDeviceCategory={(deviceCategory) => {
                        let val = {
                            target: {
                                name: 'deviceCategory',
                                value: deviceCategory
                            }
                        }
                        this.onFormChange(val).then(() => this.saveChanges())
                    }
                    }
                /> : ''}
                <DeleteModal
                    itemType={'device'}
                    item={this.props.item}
                    open={this.state.showDeleteModal}
                    onClose={() => this.setState({showDeleteModal: false})}/>
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>
            </div>
        )
    }
}

export default AssetSettingsDetails;
